import { getTemplate } from '../template';
import { getFilterSettings, getSelectedFilter } from '.';
import { FILTER_MOBILE_WRAPPER_SELECTOR } from '../constants/filter-tree';
import { formatMoney } from '../utils';

/* Renders the "Refine By" section by generating HTML from the filter tree context state.
 * check filterLayout render
 */
export function renderRefineBy(context) {
  const { filterLayout, showRefineBy } = getFilterSettings(context);

  if (!showRefineBy) return;

  const selectedFilters = getSelectedFilter(context);

  if (selectedFilters && selectedFilters?.length > 0) {
    const data = {
      refineBy: {
        title: 'REFINE BY',
        options: selectedFilters,
      },
    };

    if (filterLayout === 'horizontal') {
      renderRefineByHorizontal(context, data);
    } else {
      renderRefineByVertical(context, data);
    }

    renderRefineByMobile(context, data);
  }
}

/**
 * Renders the "Refine By" section by generating HTML from the filter tree context state.
 * Checks for selected filters and renders their names.
 * If no filters are selected, clears the HTML.
 */
export function renderRefineByMobile(context, data) {
  const doc = context.$('#bc-refine-by-mobile');
  let domRefineBy = '';

  const refineBys = document.querySelectorAll('.boost-sd__custom-refine-by-mobile');

  if (data && (refineBys.length || doc)) {
    domRefineBy = context.templateRender(getTemplate(context).refineByTemplate, data);
  }

  refineBys?.forEach((renderRefineBy) => {
    renderRefineBy.innerHTML = domRefineBy;
  });

  // mobile style1
  if (doc) {
    doc.innerHTML = domRefineBy;
  } else {
    // mobile other style find boost-sd__filter-option-mobile-items-selected bind
    const selectedByKey = {};
    data?.refineBy?.options?.forEach((select) => {
      if (!select.metaData) return;

      const _key = select.metaData.key;
      if (_key && select.metaData.value) {
        let _value = select.data?.valueDisplay || select.data?.value || select.metaData?.value;
        if (select?.type === 'range' && _value?.lower && _value?.upper) {
          const { lower, upper } = _value;
          if (!isNaN(lower) && !isNaN(upper)) {
            const format = select.data?.moneyFormatValue || '{{amount}}';
            _value = `${formatMoney(context, lower, format, false)} - ${formatMoney(
              context,
              upper,
              format,
              false
            )}`;
          }
        }

        if (!selectedByKey[_key]) {
          selectedByKey[_key] = [_value];
        } else {
          selectedByKey[_key] = [...selectedByKey[_key], _value];
        }
      }
    });

    Object.keys(selectedByKey)?.forEach((optionId) => {
      const mobileItemsSelected = context.$(
        `${FILTER_MOBILE_WRAPPER_SELECTOR} #${optionId?.replace(
          /::/g,
          '__'
        )}-toggle-target-label .boost-sd__filter-option-mobile-items-selected`
      );
      if (mobileItemsSelected) {
        mobileItemsSelected.innerHTML = selectedByKey[optionId]?.toString(); // reset selected
      }
    });
  }
}

/**
 * Renders the "Refine By" section by generating HTML from the filter tree context state.
 * Checks for selected filters and renders their names.
 * If no filters are selected, clears the HTML.
 */
export function renderRefineByVertical(context, data) {
  const doc = context.$('#bc-refine-by');

  if (!doc) return;

  if (data) {
    doc.innerHTML = context.templateRender(getTemplate(context).refineByTemplate, data);
  } else {
    doc.innerHTML = '';
  }
}

export function renderRefineByHorizontal(context, data) {
  const doc = context.$('#boost-sd__refine-by-horizontal-place');

  if (!doc) return;

  if (data) {
    doc.innerHTML = context.templateRender(getTemplate(context).refineByHorizontalTemplate, data);
  } else {
    doc.innerHTML = '';
  }
}
