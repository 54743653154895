import { templateRender } from '../../template/templateRender.js';
import { getTemplateByIds } from '../../api/template.js';

export const renderCollectionHeader = async (context) => {
  const id = context.app.templateMetadata?.collectionHeader || 'collection_header_id';
  const templatePromise = getTemplateByIds(context, context.app.templateMetadata.themeNameLib, [
    {
      name: 'collectionHeader',
      id,
    },
  ]);
  const collectionPromise = getCollectionData(context);
  const htmlPromise = Promise.all([templatePromise, collectionPromise]).then(async (list) => {
    const templates = list[0].templates;
    const collection = list[1];
    return context.templateRender(templates[id], collection);
  });
  context.render(() => {
    htmlPromise.then((html) => {
      const dom = context.$('#boost-sd__collection-header');
      if (dom) {
        dom.innerHTML = html;
      }
    });
  }, ['filterTree']);
};

const getCollectionData = async (context) => {
  if (!context.app.generalSettings.collection_id) {
    return {
      title: context.app?.translation?.collectionHeader?.collectionAllProduct || 'Products',
    };
  }

  return fetch(`${window.location.origin}${window.location.pathname}.json`).then(async (res) => {
    if (res.status === 200) {
      const toJson = await res.json();
      if (toJson.collection.image?.src) {
        const tempImg = new Image();
        tempImg.src = toJson.collection.image?.src; // cache image before inject collection header into dom
      }
      return toJson.collection;
    }
    return { title: 'Products' };
  });
};
