/**
 * Initializes DOM manipulation helpers on the provided context.
 *
 * @param {Object} context - The context to initialize the DOM helpers on.
 */
export function initDom(context) {
  context.$ = function (query, dom) {
    if (
      (typeof query === 'string' && query.startsWith('.')) ||
      (typeof query === 'string' && query.startsWith('#')) ||
      (typeof query === 'string' && query.startsWith('['))
    )
      return querySelector(query, dom);

    function getById(id) {
      return document.getElementById(id);
    }

    function getByClass(className) {
      return document.getElementsByClassName(className);
    }

    function getByTag(tagName) {
      return document.getElementsByTagName(tagName);
    }
    function attr(target, attrName) {
      return target.getAttribute(attrName);
    }
    function querySelector(selector, dom) {
      if (!(dom instanceof Element)) {
        return context.document?.querySelector(selector);
      }
      return dom.querySelector(selector);
    }

    function querySelectorAll(selector) {
      return context.document.querySelectorAll(selector);
    }

    function addClass(element, className) {
      element.classList.add(className);
    }

    function removeClass(element, className) {
      element.classList.remove(className);
    }

    function toggleClass(element, className) {
      element.classList.toggle(className);
    }

    function append(parent, child) {
      parent.appendChild(child);
    }

    function on(element, event, handler) {
      element.addEventListener(event, handler);
    }

    function off(element, event, handler) {
      element.removeEventListener(event, handler);
    }

    function triggerEvent(element, eventName) {
      const event = new Event(eventName);
      element.dispatchEvent(event);
    }
    return {
      getById,
      getByClass,
      getByTag,
      attr,
      querySelector,
      querySelectorAll,
      addClass,
      removeClass,
      toggleClass,
      append,
      on,
      off,
      triggerEvent,
    };
  };
}

export const preventAllEvents = (e) => {
  // prevent all event
  e.stopImmediatePropagation();
  e.stopPropagation();
  e.preventDefault();
};

export const removeWhiteSpaceInDivHTML = (html) => {
  const regex = /<div\s+([^<>]+?)\s*>(\s*)<\/div>/g;
  html = html.replace(regex, (match, attributes, content) => {
    if (content.trim() === '') {
      return `<div ${attributes}></div>`;
    } else {
      return match;
    }
  });
  return html;
};
