/** FOR MONEY FORMAT */

import { stripHtml } from '.';
import { getWindowLocation } from './browser';
import { isPageType, isSearchPage, isTagPage, isVendorPage } from './validate';

export const formatCurrency = ({
  context,
  value,
  showCurrencyCodes,
  showCentAsSuperscript,
  removeDecimalPoint,
  isPriceCompare,
  removePriceDecimal,
}) => {
  const moneyFormat = () => {
    if (value === null || value === undefined) return null;
    const formatType = showCurrencyCodes
      ? context?.app?.shop?.money_format_with_currency
      : context?.app?.shop?.money_format;

    return formatMoney(
      context,
      Number(value),
      formatType,
      false,
      showCentAsSuperscript,
      removePriceDecimal,
      removeDecimalPoint
    );
  };

  if (!moneyFormat) return null;

  return `${moneyFormat()}`;
};

/**
 * Formats the given money value according to the specified format options.
 * @param {number|string} money - The money value to be formatted.
 * @param {string} [format] - The format string to be used for formatting. If not provided, the default format from the app configuration will be used.
 * @param {boolean} [withTrailingZeros] - Specifies whether to include trailing zeros in the formatted value.
 * @param {boolean} [showCentAsSuperscript] - Specifies whether to display the cent value as superscript.
 * @param {boolean} [removePriceDecimal] - Specifies whether to remove the decimal part from the formatted value.
 * @param {boolean} [removeDecimalPoint] - Specifies whether to remove the decimal point from the formatted value.
 * @returns {string} The formatted money value.
 */

export const replaceDotCommaZero = (str) => {
  // replace end string ,00 or .00 -> empty
  return str?.replace(/[\.,]00$/, '');
};

export const formatMoney = (
  context,
  money,
  format,
  withTrailingZeros,
  showCentAsSuperscript,
  removePriceDecimal,
  removeDecimalPoint
) => {
  if (!format) format = context?.app?.shop?.money_format;

  if (format === 'money_with_currency') format = context?.app?.shop?.money_format_with_currency;
  if (typeof money === 'string') {
    money = money.replace('.', '');
  }

  // strip html format money fix bug PFSN-54280
  format = stripHtml(format);

  // Get type format money
  const typeFormatMoney = getTypeFormatMoney(format);

  // Format money by type
  let moneyFormat = formatMoneyByType(typeFormatMoney, money);

  // Format money with trailing zeros
  if (!withTrailingZeros) moneyFormat = replaceDotCommaZero(moneyFormat);

  // Remove decimal
  if (removePriceDecimal) {
    moneyFormat = removeDecimal(moneyFormat, context?.app?.generalSettings?.decimalDelimiter, null);
  }

  // Format money with cent as superscript
  if (showCentAsSuperscript) {
    const [ints, cents] = separateDecimalMoney(moneyFormat, typeFormatMoney, removeDecimalPoint);
    if (cents) moneyFormat = `${ints}<sup>${cents}</sup>`;
  }

  // Replace config format with money format
  return compileMoneyWithConfigFormat(moneyFormat, format);
};

export const getTypeFormatMoney = (format) => {
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  const formatString = format || '${{amount}}';
  const matches = formatString.match(placeholderRegex);
  const match = matches && matches.length > 1 ? matches[1] : '';

  return match;
};

export const formatMoneyByType = (type, money) => {
  function defaultOption(opt, def) {
    return typeof opt == 'undefined' ? def : opt;
  }

  function formatWithDelimiters(number, precision, thousands, decimal) {
    if (number !== 0 && !number) return '';

    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, ',');
    decimal = defaultOption(decimal, '.');

    number = parseFloat(`${number}`).toFixed(precision);

    const parts = number.toString().split('.');
    const dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands);
    const money = parts[1] ? decimal + parts[1] : '';

    return dollars + money;
  }

  switch (type) {
    case 'amount':
      return formatWithDelimiters(money, 2);
    case 'amount_no_decimals':
      return formatWithDelimiters(money, 0);
    case 'amount_with_comma_separator':
      return formatWithDelimiters(money, 2, '.', ',');
    case 'amount_no_decimals_with_comma_separator':
      return formatWithDelimiters(money, 0, '.', ',');
    case 'amount_with_space_separator_no_comma':
      return formatWithDelimiters(money, 2);
    case 'amount_no_decimals_with_space_separator':
      return formatWithDelimiters(money, 0, ' ', '.');
    default:
      return formatWithDelimiters(money, 2);
  }
};

export const compileMoneyWithConfigFormat = (moneyFormat, format) => {
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  let formatString = format || '${{amount}}';

  // TODO: Handle stripHtml
  // formatString = stripHtml(formatString).replace(placeholderRegex, moneyFormat);
  formatString = formatString.replace(placeholderRegex, moneyFormat);

  return formatString;
};

/**
 * Remove decimals from a number
 * @param {(string|number)} value The number value
 * @param {string} delimiter Delimiter symbol
 */
export const removeDecimal = (value, delimiter) => {
  const reg = new RegExp('(\\' + delimiter + '\\d+)+', 'gi');
  return ('' + value).replace(reg, '');
};

export const getDecimalPoint = (format) => {
  switch (format) {
    case 'amount':
    case 'amount_with_space_separator_no_comma':
      return '.';
    case 'amount_with_comma_separator':
      return ',';
    case 'amount_no_decimals':
    case 'amount_no_decimals_with_comma_separator':
    case 'amount_no_decimals_with_space_separator':
    default:
      return null;
  }
};

export const separateDecimalMoney = (moneyFormat, format, removeDecimalPoint) => {
  const decimalPoint = getDecimalPoint(format);
  if (!decimalPoint) return [`${moneyFormat}`];

  const [ints, cents] = `${moneyFormat}`.split(decimalPoint);

  return cents ? [ints, removeDecimalPoint ? `${cents}` : `${decimalPoint}${cents}`] : [ints];
};

/** END FOR MONEY FORMAT */

export const buildProductDetailUrl = (context, handle, hasCollection, current_tags) => {
  if (!handle) return '/';

  const pathname = window.location.pathname;
  const elements = pathname.split('/');

  // Check if has locale
  let localeURLPart = '';
  localeURLPart = (window?.Shopify?.routes?.root || '/')?.replace(/\/$/, '');

  // check if have hasCollection
  if (hasCollection) {
    // Homepage or Search page
    if (pathname === '/' || isSearchPage(context) || isVendorPage() || isPageType()) {
      return `${localeURLPart}/collections/all/products/${handle}`;
    } else if (isTagPage(current_tags)) {
      const preHandle = localeURLPart + '/collections/';
      const collectionHandleIndex = elements.indexOf('collections') + 1;
      if (elements.length >= 4)
        return preHandle + elements[collectionHandleIndex] + '/products/' + handle;

      return `${localeURLPart}/products/${handle}`;
    } else {
      const params = getWindowLocation().search.substring(1);
      // Google cache URL
      // An URL of Google cache will look like this: webcache.googleusercontent.com/search?q=cache:xxx:https://xxx.xxx/collections/xxx+&....
      if (params.indexOf('cache:') > -1) {
        let collectionHandle = 'all';
        const temp = params.split('&')[0].split('?')[0].split('collections/');
        if (temp.length > 1) {
          if (temp[1].indexOf('/') > -1) {
            collectionHandle = temp[1].split('/')[0];
          } else {
            collectionHandle = temp[1];
          }
        }
        collectionHandle = collectionHandle.replace(
          // eslint-disable-next-line no-useless-escape
          /[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/g,
          ''
        );

        if (!collectionHandle) collectionHandle = 'all';

        return '/collections/' + collectionHandle + '/products/' + handle;
      }

      //normal
      const collectionHandleIndex = elements.indexOf('collections') + 1;
      const preHandle = localeURLPart + '/collections/';
      if (typeof elements[2] !== 'undefined') {
        // Build for collection pages
        if (elements.includes('collections')) {
          const _collectionHandle = elements[collectionHandleIndex] || 'all';
          return preHandle + _collectionHandle + '/products/' + handle;
        }
      }
    }
  }

  return `${localeURLPart}/products/${handle}`;
};

export const buildProductDetailUrlWithVariant = (
  context,
  { variants, variant_id, handle, split_product },
  hasCollection,
  current_tags,
  variantId
) => {
  const variantIdPart =
    variantId || (split_product && variants) ? `?variant=${variantId || variant_id}` : '';

  const productUrl = buildProductDetailUrl(context, handle, hasCollection, current_tags);

  return productUrl + variantIdPart;
};

export const addParamsLocale = (params) => {
  if (parseFloat(`${window.Shopify?.currency?.rate}`) === 1) return params;

  return {
    ...params,
    currency_rate: window.Shopify?.currency?.rate,
    currency: window.Shopify?.currency?.active,
    country: window.Shopify?.country,
    return_all_currency_fields: false,
  };
};
