// TODO: Cache, XSS
import DOMPurify from 'dompurify';
import {
  debounce,
  isMobile,
  buildUrlWithLocalization,
  stripHtml,
  awaitAnimationElementFinished,
  preventAllEvents,
  getSearchSettings,
  removeWhiteSpaceInDivHTML,
  formatCurrency,
  setLocalStorage,
} from '../utils';
import {
  getProductsDetail,
  getProductsDetailByHandle,
  getRedirects,
  getSuggestionSearch,
} from '../api/search.js';
import { getOnClickRecentSearches, setOnClickRecentSearches } from './recent-search.js';
import { getTemplateByIds } from '../api/template.js';
import { CLICK_SUGGESTION_TERM, SUGGESTION_DATA } from '../constants/app';

let keyboardNavIndex = -1;
let currentSearch = '';

export const handleInstantSearchWidget = async (context) => {
  const [getInstantSearch, setInstantSearch] = context.useContextState('instant-search', {});

  context.render(() => {
    const instantSearch = getInstantSearch();
    if (instantSearch && context.templateISW) {
      // Render data
      let html = context.templateRender(context.templateISW, instantSearch);
      html = DOMPurify.sanitize(html);
      html = removeWhiteSpaceInDivHTML(html);

      const instantSearchDom = context.document.querySelector(
        '.boost-sd__instant-search-container'
      );
      if (instantSearchDom) {
        // Already has instant search html, update result to html
        replaceHTML(context, html);
      } else {
        // Create default html for instant search
        context.document.innerHTML = html;
      }

      const suggestions = context.document.querySelectorAll(
        '.boost-sd__suggestion-queries-list li.boost-sd__suggestion-queries-item--suggestion'
      );

      suggestions.forEach((suggestion) => {
        suggestion.addEventListener('click', (e) => {
          setLocalStorage(CLICK_SUGGESTION_TERM, suggestion.getAttribute('data-title'));
        });
      });
      //Generate newId for customize
      const newId = window.boostWidgetIntegration.generateUuid();
      context.state.latestInstantSearchRequest = newId;
    }
  }, ['instant-search']);

  initInstantSearchWidget(context);
};

const initInstantSearchWidget = async (context) => {
  const [getInstantSearch, setInstantSearch] = context.useContextState('instant-search', {});
  const [getIsMobile, setIsMobile] = context.useContextState('is-mobile', false);
  context.templateISW = await requestInstantSearchWidgetTemplate(context); // Get template for ISW

  // Build default data
  context.defaultData = await buildDefaultDataSuggestion(context);

  // Update responsive state
  setIsMobile(isMobile(context.app.generalSettings?.isTabletPortraitMax));

  // Set default instant search state
  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get('q');
  if (!!query) {
    // Get data for instant search
    // Loading
    const instantSearch = getInstantSearch();
    const { isLoading = false } = instantSearch;
    if (!isLoading) {
      setInstantSearch({ ...context.defaultData, isLoading: true });
    }

    // Get data
    const data = await requestSuggestion(context, query);

    setInstantSearch({ ...context.defaultData, ...data, isLoading: false });
  } else {
    setInstantSearch(context.defaultData);
  }

  const inputs = document.querySelectorAll(`input[name="q"]:not([data-disable-instant-search])`);

  // Bind id, name, and event for input
  inputs.forEach((input, index) => {
    input.id = `boost-sd__search-widget-init-input-${index}`;
    input.classList.add('boost-sd__search-widget-init-input');
    input.setAttribute('aria-expanded', 'false');

    // disabled autocomplete of chrome
    input.setAttribute('autocomplete', 'off');
    input.setAttribute('data-search-box', `boost-sd__search-widget-init-input-${index}`);
    input.setAttribute('role', 'combobox');
    input.setAttribute('aria-expanded', 'false');
    input.setAttribute('aria-autocomplete', 'list');
    input.setAttribute('aria-label', ''); // Hard code
    input.setAttribute('maxlength', '150');
    input.setAttribute(
      'placeholder',
      context.app?.translation?.suggestion?.searchBoxPlaceholder || 'Search'
    );
    input.setAttribute('value', !!query ? stripHtml(query) : '');

    // Bind event
    input.addEventListener('input', (e) => {
      handleSearchInputChange(context, e, input);
    });

    input.addEventListener('click', async (e) => {
      searchInputListener(context, e, input);
    });

    input.addEventListener('focus', async (e) => {
      searchInputListener(context, e, input);
    });

    if (!window.boostSdDisableISWKeyDown) {
      input.addEventListener('keydown', (e) => {
        searchInputKeydownListener(context, e, input);
      });
    }

    // detect button: search and reset
    const thisSearchForm = input.closest('form');
    if (thisSearchForm) {
      // Button search
      const thisSearchButton = thisSearchForm.querySelector('[type="submit"], .search__button');
      if (thisSearchButton) {
        thisSearchButton.addEventListener('click', (e) => {
          searchButtonListener(context, e, input);
        });
      }

      // Button reset
      const thisResetButton = thisSearchForm.querySelector(
        '[type="reset"], .reset__button, .boost-sd__button-text-x--clear'
      );
      if (thisResetButton) {
        if (!!query) {
          thisResetButton.classList.remove('boost-sd__g-hide');
        }
        thisResetButton.addEventListener('click', () => {
          resetButtonListener(input, thisResetButton);
        });
      }

      // Button close - available on full-width
      const closeButton = thisSearchForm.querySelector('.boost-sd__button-text-x--close');
      if (closeButton) {
        closeButton.addEventListener('click', function () {
          onCloseSuggestion(context);
        });
      }
    }

    if (document.activeElement === input) {
      searchInputListener(context, null, input);
    }
  });

  // Bind event document
  // Event click on document to close instant search
  const debounceDocumentClick = debounce((e) => {
    const isMobileValue = isMobile(context.app.generalSettings?.isTabletPortraitMax);
    const suggestionSelector = context.document.querySelector(
      `.boost-sd__instant-search-container-${isMobileValue ? 'mobile' : 'desktop'} > div`
    );

    const inputId = suggestionSelector?.getAttribute('aria-controls');
    const input = document.getElementById(inputId);

    if (isSuggestionOpening(suggestionSelector)) {
      // click Outside check close suggestion results
      clickOutsideSuggestion(context, suggestionSelector, input, e);
    }
  }, 100);

  // Bind the event click
  document.addEventListener('click', debounceDocumentClick, true);

  // Event resize the window => change between mobile/desktop mode and re-calculate the position
  const debounceResize = debounce(async () => {
    const [getIsMobile, setIsMobile] = context.useContextState('is-mobile', false);
    const isMobileValue = isMobile(context.app.generalSettings?.isTabletPortraitMax);

    if (getIsMobile() !== isMobileValue) {
      // Responsive to another style desktop <-> mobile
      // Remove all selected
      keyboardNavIndex = -1;
      const input = document.querySelector(
        "input[aria-activedescendant='boost-sd__suggestion-queries-item-selected']"
      );
      removeCurrentSelected(context, input);
      // Close ISW
      onCloseSuggestion(context);
      // tips: trigger click, hide search of theme
      const suggestionSelector = context.document.querySelector(
        `.boost-sd__instant-search-container-${isMobileValue ? 'mobile' : 'desktop'} > div`
      );
      if (suggestionSelector) {
        suggestionSelector.click();
      }

      // Update state
      setIsMobile(isMobileValue);
    } else {
      // Responsive to the same style desktop/mobile
      // Recalculate position - Apply for non - fullwidth style
      const suggestionSelector = context.document.querySelector(
        `.boost-sd__instant-search-container-${
          isMobileValue ? 'mobile' : 'desktop'
        } .boost-sd__search-widget-init-wrapper`
      );
      if (suggestionSelector && isSuggestionOpening(suggestionSelector)) {
        const inputId = suggestionSelector?.getAttribute('aria-controls');
        const input = document.getElementById(inputId);
        if (input) {
          // await modal search display
          await awaitAnimationElementFinished(input, 100, 1000);

          calcPositionSuggestionResult(context, input);
        }
      }
    }
  }, 100);

  // Bind the resize event
  window.addEventListener('resize', debounceResize);

  document.body.classList.add('boost-sd__search-widget-init-enabled'); // Code from old project for enabled ISW css
};

// ------------COMMON FUNCTIONS------------
// Generate search page href
const generateSearchPageHref = (term = '', extraParam = '') => {
  const extraSearchParam = extraParam ? `&${extraParam.replace(/&/g, 'AND')}` : '';

  return buildUrlWithLocalization(
    `search?q=${encodeURIComponent(term)}${extraSearchParam}`
  ).replace(/%20/g, '+');
};

// Check current state is fullwidth or not
const isStyleFullWidth = (context) => {
  const isMobileValue = isMobile(context.app.generalSettings?.isTabletPortraitMax);
  const { suggestionStyle, suggestionMobileStyle } = getSearchSettings(context);
  return (
    (isMobileValue && suggestionMobileStyle === 'style1') ||
    (!isMobileValue && suggestionStyle === 'style3')
  );
};

// ---------------HANDLE DATA--------------
// Get ISW template
const requestInstantSearchWidgetTemplate = async (context) => {
  const { templates = {} } = await getTemplateByIds(
    context,
    context.app.templateMetadata?.themeNameLib,
    [{ name: 'instantSearchWidget', id: context.app.templateMetadata?.instantSearchWidget }]
  );

  return templates[context.app.templateMetadata?.instantSearchWidget] || '';
};

// Build default data for suggestion
const buildDefaultDataSuggestion = async (context) => {
  let defaultData = {
    // Data settings
    suggestionMobileStyle: 'style1', //Hard code,
    suggestionMobileProductItemType: 'grid',
    redirects: context.redirects,
    // Data state
    isLoading: false,
    // Data from API data
    all_empty: true,
    collections: [],
    correct_content_query: '',
    correct_query: '',
    did_you_mean: [],
    pages: [],
    query: '',
    suggestions: [],
    total_product: 0,
  };

  const searchSettings = getSearchSettings(context);
  // Get redirect
  context.redirects = await getRedirects(context);
  defaultData.redirects = context.redirects;

  let suggestProductsOnClick = [];
  // Get suggestion product when search box on click
  if (searchSettings?.searchBoxOnclick?.productSuggestion?.status) {
    suggestProductsOnClick = await getSuggestProducts(context);
  }
  defaultData.products = suggestProductsOnClick || [];

  // Recent search
  const recentSearches = getOnClickRecentSearches(
    searchSettings?.searchBoxOnclick?.recentSearch?.number || 10
  );
  defaultData.recentSearches = recentSearches;

  // Data from search setting
  defaultData = {
    ...defaultData,
    searchTermSuggestionList: searchSettings.searchBoxOnclick?.searchTermSuggestion?.data || [],
    searchTermSuggestionStatus:
      searchSettings.searchBoxOnclick?.searchTermSuggestion?.status || false,
    searchTermNoResultList: searchSettings.suggestionNoResult?.search_terms?.data || [],
    searchTermNoResultStatus: searchSettings.suggestionNoResult?.search_terms?.status || false,
    searchTermSuggestionLabel: searchSettings.searchBoxOnclick?.searchTermSuggestion?.label || '',
    searchTermNoResultLabel: searchSettings.suggestionNoResult?.search_terms?.label,
    suggestionBlocksList: searchSettings.suggestionBlocks,
  };

  return defaultData;
};

// Get suggestion product for search box on click
const getSuggestProducts = async (context) => {
  const searchSettings = getSearchSettings(context);
  const productIds = searchSettings?.searchBoxOnclick?.productSuggestion?.data;
  if (productIds.length === 0) return [];

  let result = await getProductsDetail(context, productIds);
  result = transformProductPrice(context, result);
  return result;
};

// Get suggestion results
const requestSuggestion = async (context, query) => {
  let data = await context.handler(getSuggestionSearch)(context, query);

  // improve logic, if allEmpty = true -> check call api trending product
  if (data.all_empty) {
    const searchSettings = getSearchSettings(context);
    // get most product if enabled
    const _mostProductsSetting = searchSettings.suggestionNoResult?.products || {};
    if (_mostProductsSetting.status && _mostProductsSetting.data.length > 0) {
      let products = await getProductsDetailByHandle(context, _mostProductsSetting.data, query);
      if (products) {
        products = transformProductPrice(context, products);
        data = { ...data, products };
      }
    }
  } else {
    data.products = transformProductPrice(context, data.products);
    setLocalStorage(SUGGESTION_DATA, { query, id: data.meta.rid, suggestions: data.suggestions });
  }
  return data;
};

const requestSuggestionDebounce = debounce(async (context, query) => {
  const [getInstantSearch, setInstantSearch] = context.useContextState('instant-search', {});

  if (!!query) {
    // Loading
    const instantSearch = getInstantSearch();
    const { isLoading = false } = instantSearch;
    if (!isLoading) {
      setInstantSearch({ ...context.defaultData, isLoading: true });
    }
    // Get data
    const data = await requestSuggestion(context, query);
    setInstantSearch({ ...context.defaultData, ...data, isLoading: false });
  } else {
    setInstantSearch(context.defaultData);
  }
}, 300);

// Change HTML from template to DOM
const replaceHTML = (context, htmlString) => {
  const instantSearchDom = context.document.querySelector('.boost-sd__instant-search-container');
  if (instantSearchDom) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlString, 'text/html');

    // Replace mobile block
    const mobileSelector =
      '.boost-sd__instant-search-container-mobile .boost-sd__instant-search-results';
    const suggestionSelectorMobile = instantSearchDom.querySelector(mobileSelector);
    const newSuggestionSelectorMobile = htmlDoc.querySelector(mobileSelector);

    if (suggestionSelectorMobile && newSuggestionSelectorMobile) {
      suggestionSelectorMobile.parentNode.replaceChild(
        newSuggestionSelectorMobile,
        suggestionSelectorMobile
      );
    }

    // Replace desktop block
    const desktopSelector =
      '.boost-sd__instant-search-container-desktop .boost-sd__instant-search-results';
    const suggestionSelectorDesktop = instantSearchDom.querySelector(desktopSelector);
    const newSuggestionSelectorDesktop = htmlDoc.querySelector(desktopSelector);

    if (suggestionSelectorDesktop && newSuggestionSelectorDesktop) {
      suggestionSelectorDesktop.parentNode.replaceChild(
        newSuggestionSelectorDesktop,
        suggestionSelectorDesktop
      );
    }
  }
};

// ------------EVENT FUNCTIONS------------
// Handle input change
export const handleSearchInputChange = (context, e, input) => {
  e.preventDefault();
  keyboardNavIndex = -1;
  removeCurrentSelected(context, input);
  currentSearch = e.target.value;

  // Button reset
  const thisSearchForm = input.closest('form');
  if (thisSearchForm) {
    const thisResetButton = thisSearchForm.querySelector(
      '[type="reset"], .reset__button, .boost-sd__button-text-x--clear'
    );
    if (thisResetButton) {
      if (!!currentSearch) {
        // Show button reset
        thisResetButton.classList.remove('boost-sd__g-hide');
      } else {
        // Hide button reset
        thisResetButton.classList.add('boost-sd__g-hide');
      }
    }
  }

  // Get data for instant search
  requestSuggestionDebounce(context, currentSearch);

  // Sync all inputs
  const inputs = document.querySelectorAll(`input[name="q"]:not([data-disable-instant-search])`);
  inputs.forEach((input) => {
    if (input !== e.target) {
      input.value = currentSearch;
    }
  });
};

// Handle click/focus input
export const searchInputListener = async (context, e, input) => {
  // check current input is not input of owner then check style
  const isOwnerInput = input.closest('.boost-sd__instant-search-container');
  if (!isOwnerInput && !isStyleFullWidth(context)) {
    // await modal search display
    await awaitAnimationElementFinished(input, 100, 1000);
  }

  calcPositionSuggestionResult(context, input);
  onOpenSuggestion(context, input);
};

export const searchInputKeydownListener = (context, e, input) => {
  const isMobileValue = isMobile(context.app.generalSettings?.isTabletPortraitMax);
  const suggestionSelector = context.document?.querySelector(
    `.boost-sd__instant-search-container-${isMobileValue ? 'mobile' : 'desktop'} > div`
  );
  if (!e || !e.key) return;

  switch (e.key) {
    // Enter key
    case 'Enter': {
      preventAllEvents(e);

      const _currentValue = stripHtml(e.target.value.trim());
      setOnClickRecentSearches(_currentValue);

      // api returned
      if (_currentValue.length > 0) {
        // check direct when enter
        const redirects = context.redirects;

        if (redirects && redirects[_currentValue?.toLowerCase()]) {
          window.location.href = redirects[_currentValue?.toLowerCase()];
        } else {
          // check if have current linkSelected then redirect else default search term
          const linkSelected = selectItemCurrentSelected(suggestionSelector);
          window.location.href = linkSelected || generateSearchPageHref(_currentValue);
        }
      } else {
        // Redirect to search page with empty search term
        window.location.href = generateSearchPageHref();
      }
      break;
    }
    // current focus input, and Tab -> move to other element -> close
    case 'Tab': {
      onCloseSuggestion(context, input);
      break;
    }
    // esc key => close
    case 'Escape': {
      return onCloseSuggestion(context, input);
    }
    //Arrow/navigation key up, down, left, right
    case 'ArrowUp':
    case 'ArrowDown':
    case 'ArrowLeft':
    case 'ArrowRight': {
      if (window.boostSdDisableISWArrow) return;

      if (isSuggestionOpening(suggestionSelector)) {
        onKeyboardNavigation(context, input, suggestionSelector, e);
      }
      break;
    }
    default: {
    }
  }
};

export const searchButtonListener = (context, e, input) => {
  e.preventDefault();

  // save recent search when click button search
  setOnClickRecentSearches(stripHtml(input.value.trim()));

  // check redirect when click button search
  const redirects = context?.redirects;

  if (redirects && redirects[input.value?.toLowerCase()]) {
    preventAllEvents(e);
    window.location.href(redirects[input.value?.toLowerCase()]);
    return;
  }

  window.location.href = generateSearchPageHref(input.value);
};

export const resetButtonListener = (input, thisResetButton) => {
  // Clear data on input
  input.value = '';
  input.dispatchEvent(new Event('input'));
  input.focus();
  // toggle reset button
  thisResetButton.classList.add('boost-sd__g-hide');
};

// Calculate position of current suggestion result modal
const calcPositionSuggestionResult = (context, input) => {
  const isMobileValue = isMobile(context.app.generalSettings?.isTabletPortraitMax);
  const suggestionSelector = context.document.querySelector(
    `.boost-sd__instant-search-container-${
      isMobileValue ? 'mobile' : 'desktop'
    } .boost-sd__search-widget-init-wrapper`
  );

  if (suggestionSelector) {
    const rect = input.getBoundingClientRect();
    const position = 'absolute';

    // + 2 px => border of input
    // + 4 px: distance from input from input autocomplete to this input
    // window.scrollY: if window has scroll Y
    const scroll = window.scrollY;
    let top = rect.height + rect.top + 2 + 4;
    // - 20: distance from suggestion to bottom of window
    const _maxHeight = window.innerHeight - top - (isStyleFullWidth(context) ? 0 : 20);

    let _positionLeftOrRight = `left: ${rect.left}px`;

    // auto detect suggestion position
    if (window.innerWidth / 2 < rect.left) {
      _positionLeftOrRight = `right: ${window.innerWidth - rect.right}px`;
    }

    top = top + scroll;

    suggestionSelector.setAttribute(
      'style',
      `position: ${position}; top: ${top}px; ${_positionLeftOrRight}; z-index: 999999998;`
    );

    const debounceScrollPage = debounce(() => {
      const header = document.getElementsByTagName('header');
      const searchForm = document.getElementsByClassName('boost-sd__search-form');
      let _zIndex;

      if (
        header.length > 0 &&
        window.scrollY > 0 &&
        searchForm[0]?.getBoundingClientRect().bottom <=
          header[0].getBoundingClientRect().top + header[0].clientHeight
      ) {
        _zIndex = 2;
      } else {
        _zIndex = 999999998;
      }

      suggestionSelector.setAttribute(
        'style',
        `position: ${position}; top: ${top}px; ${_positionLeftOrRight}; z-index: ${_zIndex};`
      );
    }, 10);

    window.addEventListener('scroll', debounceScrollPage);

    const thisResults = suggestionSelector.querySelector(
      '.boost-sd__instant-search-autocomplete-results'
    );
    if (thisResults) {
      thisResults.setAttribute(
        'style',
        `max-height: ${calcMaxHeightByStyle(context, _maxHeight)}px`
      );
    }
  }
};

// Calculate max height by style - except fullwidth
const calcMaxHeightByStyle = (context, maxHeight) => {
  const {
    suggestionStyle,
    suggestionStyle2ProductItemType,
    suggestionMobileStyle,
    suggestionMaxHeight = 0,
  } = getSearchSettings(context);
  let _maxHeight = maxHeight;

  // case current maxHeight >= maxHeightSettings -> chose maxHeightSettings
  if (suggestionMaxHeight && suggestionMaxHeight > 0 && _maxHeight >= suggestionMaxHeight) {
    return suggestionMaxHeight;
  }

  // !suggestionMaxHeight or maxHeight < suggestionMaxHeight
  if (!isMobile(context.app.generalSettings?.isTabletPortraitMax)) {
    if (suggestionStyle === 'style2' && suggestionStyle2ProductItemType === 'list') {
      // 564px
      _maxHeight = Math.min(_maxHeight, 564);
    } else {
      // 640px
      _maxHeight = Math.min(_maxHeight, 640);
    }
  } else {
    if (suggestionMobileStyle === 'style2') {
      _maxHeight = Math.min(_maxHeight, 640);
    }
  }

  return _maxHeight;
};

const isSuggestionOpening = (suggestionSelector) => {
  return !suggestionSelector?.classList?.contains('boost-sd__g-hide');
};

// Open all suggestion
const onOpenSuggestion = (context, input) => {
  const isMobileValue = isMobile(context.app?.generalSettings?.isTabletPortraitMax);

  const suggestionSelector = context.document.querySelector(
    `.boost-sd__instant-search-container-${isMobileValue ? 'mobile' : 'desktop'} > div`
  );
  if (suggestionSelector) {
    // remove some theme have overlay, can't override or click outside not close search modal -> trigger click close this button.
    // Prestige , Supper store, Venture, District (in order)
    const closeSearchThemeSelector = context.app?.generalSettings?.closeSearchThemeSelector;

    const closeSearchThemes = document.querySelectorAll(
      `#Search .Search__Close,
        .live-search-takeover-cancel,
        .js-drawer-open .page-container,
        .menu-drawer__modal-close.modal__close`.concat(
        `${closeSearchThemeSelector ? `, ${closeSearchThemeSelector}` : ''}`
      )
    );

    if (closeSearchThemes?.length > 0) {
      closeSearchThemes.forEach((closeSearchTheme) => closeSearchTheme.click());
    }

    if (!isSuggestionOpening(suggestionSelector)) {
      suggestionSelector.classList.remove('boost-sd__g-hide');
      suggestionSelector.setAttribute('aria-controls', input.id);
    }

    if (isStyleFullWidth(context) || !input.classList.contains('boost-sd__search-form-input')) {
      document.body.classList.add('boost-sd__search-opening');
    }

    input.setAttribute('aria-expanded', 'true');
  }

  // Handle full-width
  const searchBarInput = context.document.querySelector(
    `.boost-sd__instant-search-container-${
      isMobileValue ? 'mobile' : 'desktop'
    } .boost-sd__search-bar-input`
  );

  const preventInfiniteISW = context.app?.generalSettings?.preventInfiniteISW;
  const searchElement = document.querySelector('.header__icon-wrapper');

  if (searchBarInput) {
    if (preventInfiniteISW || searchElement) {
      /**
       * ISSUE: Infinite uncaught errors - Issue occurred after fixing PFSN-43210
       * TARGET: focal theme
       */
      setTimeout(() => searchBarInput.focus(), 200);
    } else {
      searchBarInput.focus();
    }
    document.body.classList.add('boost-sd__g-no-scroll');
  }
};

// Close all suggestion
const onCloseSuggestion = (context, input) => {
  const hide = () => {
    const isMobileValue = isMobile(context.app?.generalSettings?.isTabletPortraitMax);
    const suggestionSelector = context.document.querySelector(
      `.boost-sd__instant-search-container-${isMobileValue ? 'mobile' : 'desktop'} > div`
    );
    if (suggestionSelector) {
      suggestionSelector.classList.add('boost-sd__g-hide');
      suggestionSelector.setAttribute('aria-controls', '');

      document.body.classList.remove('boost-sd__g-no-scroll');
      document.body.classList.remove('boost-sd__search-opening');

      if (input) {
        input.setAttribute('aria-expanded', 'false');
      }
    }
  };

  if (document.body.classList.contains('show-search')) {
    // close ISW full-width - Symmetry Theme
    document.body.classList.remove('show-search');
    setTimeout(hide, 200);
    return;
  }

  hide();
};

// Handle keyboard up-down: select/remove item
const onKeyboardNavigation = (context, input, suggestionSelector, e) => {
  if (!suggestionSelector) return;
  if (!isSuggestionOpening(suggestionSelector) || !e || !e.key) return;
  const suggestionItems = suggestionSelector.querySelectorAll('.boost-sd__suggestion-queries-item');

  if (!suggestionItems || suggestionItems.length === 0) return;
  const viewAllProducts = suggestionSelector.querySelector('[data-group="view-all-products"]');
  const numberOfItems = suggestionItems.length + (viewAllProducts ? 1 : 0);
  let isStopPropagation = false;

  if (e.key.includes('Down') || e.key.includes('Up')) {
    isStopPropagation = true;

    if (e.key.includes('Up')) {
      keyboardNavIndex--;
      if (keyboardNavIndex < -1) {
        keyboardNavIndex = numberOfItems - 1;
      }
    } else if (e.key.includes('Down')) {
      keyboardNavIndex++;
      if (keyboardNavIndex > numberOfItems - 1) {
        keyboardNavIndex = -1;
      }
    }
  }

  input.focus();

  if (keyboardNavIndex === -1) {
    // select input
    if (currentSearch) {
      input.value = currentSearch;
    }
    removeCurrentSelected(context, input);
  } else if (viewAllProducts && keyboardNavIndex > suggestionItems.length - 1) {
    // view all
    if (currentSearch) {
      input.value = currentSearch;
    }
    removeCurrentSelected(context, input);
    viewAllProducts?.classList.add('boost-sd__suggestion-queries-item-selected');
    viewAllProducts.setAttribute('aria-selected', 'true');
  } else {
    removeCurrentSelected(context, input);
    suggestionItems.forEach((item, index) => {
      if (keyboardNavIndex === index) {
        addItemSelected(item, input);
      }
    });
  }

  if (e.key.includes('Left') || e.key.includes('Right')) {
    if (keyboardNavIndex !== -1) {
      isStopPropagation = true;
    }
  }

  if (isStopPropagation) {
    preventAllEvents(e);
  }
};

const addItemSelected = (item, input) => {
  const idActiveDescendantFocus = 'boost-sd__suggestion-queries-item-selected';

  const textValue = item.getAttribute('data-title');
  input.value = textValue || '';
  input.setAttribute('aria-activedescendant', idActiveDescendantFocus);
  item.id = idActiveDescendantFocus;
  item.classList.add('boost-sd__suggestion-queries-item-selected');
  item.setAttribute('aria-selected', 'true');
};

const removeCurrentSelected = (context, input) => {
  const [getIsMobile, setIsMobile] = context.useContextState('is-mobile', false);

  if (input) {
    input.removeAttribute('aria-activedescendant');
  }

  const suggestionSelector = context.document.querySelector(
    `.boost-sd__instant-search-container-${getIsMobile() ? 'mobile' : 'desktop'} > div`
  );

  if (suggestionSelector) {
    const itemSelected = suggestionSelector.querySelector(
      `.boost-sd__suggestion-queries-item-selected, [data-group="view-all-products"]`
    );

    if (itemSelected) {
      itemSelected.removeAttribute('id');
      itemSelected.classList.remove('boost-sd__suggestion-queries-item-selected');
      itemSelected.setAttribute('aria-selected', 'false');
    }
  }
};

const selectItemCurrentSelected = (suggestionSelector) => {
  if (!isSuggestionOpening(suggestionSelector)) return;

  const selectElement = suggestionSelector.querySelector(
    `boost-sd__suggestion-queries-item-selected > a`
  );
  if (selectElement) {
    const link = selectElement.getAttribute('href');
    if (link && !isBadUrl(link)) {
      return link;
    }
  }

  return false;
};

// Handle click outside the suggestion container
const clickOutsideSuggestion = (context, suggestionSelector, input, e) => {
  // not apply style full width
  if (isStyleFullWidth(context) || !input) return;

  let doClose = false;

  if (isSuggestionOpening(suggestionSelector) && e && e.target) {
    const activeElement = e.target;
    const isClickThisInput = activeElement?.id === input?.id;
    const isClickThisSearchButton =
      activeElement.closest('form') && activeElement.closest('[type="submit"]');
    const isClickSuggestion = activeElement.closest('.boost-sd__instant-search-container');
    const isClickHeaderIconSearch = activeElement.closest('.header__icon--search');
    const hasSearchModal = input.closest('.search-modal');

    if (
      !isClickThisInput &&
      !isClickThisSearchButton &&
      !isClickSuggestion &&
      !isClickHeaderIconSearch
    ) {
      doClose = true;
    }

    // if click Suggestion & theme not turn off hasSearchModal
    if (isClickSuggestion && hasSearchModal) {
      doClose = true;
    }
  } else if (!e) {
    doClose = true;
  }

  if (doClose) {
    onCloseSuggestion(context, input);
    document.body.classList.remove('boost-sd__search-opening');
  } else {
    if (!input.classList.contains('boost-sd__search-form-input')) {
      document.body.classList.add('boost-sd__search-opening');
    }
  }
};

const transformProductPrice = (context, products) => {
  const { showCentAsSuperscript, showCurrencyCodes } =
    context?.templateSettings?.themeSettings?.productItems?.productInfo?.elements?.price || {};

  products?.forEach((productItem) => {
    const { price_min, compare_at_price_min } = productItem;

    productItem.price_min_format = formatCurrency({
      context,
      value: Number(price_min),
      showCurrencyCodes,
      showCentAsSuperscript,
      removeDecimalPoint: showCentAsSuperscript,
    });

    productItem.compare_at_price_min_format = formatCurrency({
      context,
      value: Number(compare_at_price_min),
      showCurrencyCodes,
      showCentAsSuperscript,
      removeDecimalPoint: showCentAsSuperscript,
    });
  });

  return products;
};
