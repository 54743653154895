import { initAnalytic } from './analytic';
import { initApp } from './app';
import { SESSION_KEY } from './constants/app';
import { getLocalStorage, setLocalStorage, isProductPage, addRecentlyViewedProduct } from './utils';
import { templateManagement } from './template/template-management';

console.log('jslib loaded ', new Date(), '-', new Date().getMilliseconds(), 'ms');
(function () {
  window.boostWidgetIntegration = window.boostWidgetIntegration || {};
  window.boostWidgetIntegration.generateUuid = function () {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function (c) {
      var r = (Math.random() * 16) | 0;
      return r.toString(16);
    });
  };
  window.boostWidgetIntegration.getSessionId = function () {
    try {
      let sessionId = getLocalStorage(SESSION_KEY);

      if (!sessionId) {
        sessionId = window.boostWidgetIntegration.generateUuid();
        setLocalStorage(SESSION_KEY, sessionId);
      }

      return sessionId;
    } catch (error) {
      console.log('getSessionId', error);
      return window.boostWidgetIntegration.generateUuid();
    }
  };
  let retryTime = 0;
  function initBlocks() {
    // ensure blocks are initialized
    if (window.boostWidgetIntegration.status === 'ready') {
      console.log(
        'jslib & blocks finish init ',
        new Date(),
        '-',
        new Date().getMilliseconds(),
        'ms'
      );
      const blocks = window.boostWidgetIntegration.blocks || {};
      if (Object.keys(blocks).length > 0) {
        for (const blockId in blocks) {
          if (!blocks[blockId].app.filterSettings.filterLayout) {
            blocks[blockId].app.filterSettings.filterLayout = 'vertical';
          }
          initApp(blocks[blockId]);
        }
      }
      // init template management
      if (Object.values(blocks).length > 0) {
        // Client render will start after 30 seconds to avoid affecting the first load process
        setTimeout(() => {
          templateManagement(Object.values(blocks)[0]);
        }, 30000);
      }

      if (isProductPage() && window.boostSDData?.product) {
        addRecentlyViewedProduct(window.boostSDData.product.id);
      }
    } else {
      console.log(
        'blocks not yet loaded, window.boostWidgetIntegration.status =',
        window.boostWidgetIntegration.status,
        'retry after 100ms'
      );
      // retry init block in 10s
      retryTime++;
      if (retryTime < 100) {
        setTimeout(() => {
          initBlocks();
        }, 100);
      }
    }
  }
  initBlocks();
  initAnalytic();
})();
