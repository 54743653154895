export const refineByTemplate = `
<div class='boost-sd__refine-by-vertical-refine-by'>
  <div class='boost-sd__refine-by-vertical-refine-by-title'>
    <div class='boost-sd__refine-by-vertical-refine-by-heading'>
      {{ 'refine' | translate: translations, 'Refine By' }}
    </div>
    <button
      type='button'
      aria-label='Clear all filters'
      class='boost-sd__button boost-sd__button--clear'
      data-metadata='{ "action": { "clearAllFilter": "" } }'
    >
      <span class='boost-sd__button-text' data-metadata='{ "action": { "clearAllFilter": "" } }'>
        {{- 'clearAll' | translate: translations, 'Clear All' -}}
      </span>
    </button>
  </div>
  <div class='boost-sd__refine-by-vertical-refine-by-list'>
    {% for refineByOption in refineBy.options %}
      <button
        data-metadata='{"action": { "removeFilterFromRefineBy": {{ refineByOption.metaData | json }} } }'
        class='boost-sd__refine-by-vertical-refine-by-item'
      >
        <div
          data-metadata='{"action": { "removeFilterFromRefineBy": {{ refineByOption.metaData | json }} } }'
          class='boost-sd__refine-by-vertical-refine-by-type'
        >
          <span
            data-metadata='{"action": { "removeFilterFromRefineBy": {{ refineByOption.metaData | json }} } }'
            class='boost-sd__refine-by-vertical-refine-by-option'
          >
            {{ refineByOption.data.label | escape }}:
          </span>
          <span
            data-metadata='{"action": { "removeFilterFromRefineBy": {{ refineByOption.metaData | json }} } }'
            class='boost-sd__refine-by-vertical-refine-by-value'
          >
            {% if refineByOption.type == 'range' %}
              {% if refineByOption.data.key == 'pf_p_price' or refineByOption.data.key == 'pf_vp_variants_price' %}
                {{
                  refineByOption.data.value.lower
                  | currency_format: refineByOption.data.moneyFormatValue
                }}
                -
                {{
                  refineByOption.data.value.upper
                  | currency_format: refineByOption.data.moneyFormatValue
                }}
              {% else %}
                {{ refineByOption.data.value.lower }} - {{ refineByOption.data.value.upper }}
              {% endif %}
            {% else %}
              {{ refineByOption.data.valueDisplay }}
            {% endif %}
          </span>
        </div>
        <svg
          data-metadata='{"action": { "removeFilterFromRefineBy": {{ refineByOption.metaData | json }} } }'
          class='boost-sd__refine-by-vertical-refine-by-clear'
          xmlns='http://www.w3.org/2000/svg'
          width='12'
          height='12'
          viewBox='0 0 12 12'
          fill='#222'
        >
          <path
          data-metadata='{"action": { "removeFilterFromRefineBy": {{ refineByOption.metaData | json }} } }'
          fill-rule="evenodd" clip-rule="evenodd" d="M7.41401 6.00001L11.707 1.70701C12.098 1.31601 12.098 0.684006 11.707 0.293006C11.316 -0.0979941 10.684 -0.0979941 10.293 0.293006L6.00001 4.58601L1.70701 0.293006C1.31601 -0.0979941 0.684006 -0.0979941 0.293006 0.293006C-0.0979941 0.684006 -0.0979941 1.31601 0.293006 1.70701L4.58601 6.00001L0.293006 10.293C-0.0979941 10.684 -0.0979941 11.316 0.293006 11.707C0.488006 11.902 0.744006 12 1.00001 12C1.25601 12 1.51201 11.902 1.70701 11.707L6.00001 7.41401L10.293 11.707C10.488 11.902 10.744 12 11 12C11.256 12 11.512 11.902 11.707 11.707C12.098 11.316 12.098 10.684 11.707 10.293L7.41401 6.00001Z" fill="#3D4246">
          </path>
        </svg>
      </button>
    {% endfor %}
  </div>
</div>`;
