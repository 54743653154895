export const getThemeSettings = (context) => {
  return context.templateSettings?.themeSettings;
};

export const getSearchSettings = (context) => {
  if (!context) {
    console.error('context getSearchSettings null');
    return {};
  }

  let searchSettings =
    context?.templateSettings?.searchSettings || context?.app?.searchSettings || {};

  // Hard code with mobile style
  searchSettings = {
    ...searchSettings,
    suggestionMobileStyle: 'style1',
    suggestionMobileProductItemType: 'grid',
  };
  return searchSettings;
};
