import { getTemplateByIds } from '../api/template';
import { templateParse } from '../template/templateRender';

export async function templateManagement(context) {
  if (context.app.templateMetadata) {
    const templateNames = [
      'collectionHeader',
      'cart',
      'collectionFilters',
      'quickView',
      'recommendation',
      'searchPage',
    ];
    const metafieldTemplateNames = Object.keys(context.app?.template);
    const widgets = [];
    templateNames
      .filter((template) => !metafieldTemplateNames.includes(template))
      .forEach((template) => {
        if (context.app?.templateMetadata[template]) {
          widgets.push({
            name: template,
            id: context.app.templateMetadata[template],
          });
        }
      });
    if (widgets.length > 0) {
      const templateRes = await getTemplateByIds(
        context,
        context.app.templateMetadata.themeNameLib,
        widgets
      );
      if (templateRes && templateRes.templates && Object.keys(templateRes.templates).length > 0) {
        // handle add template to context
        templateNames.forEach((template) => {
          const id = context.app.templateMetadata[template];
          if (id && templateRes.templates[id]) {
            context.app.template[template] = templateRes.templates[id];
          }
        });
        context.app.templateParsed = {};
        if (context?.app?.template && context?.app?.template.length > 0) {
          Object.keys(context.app.template).forEach((templateName) => {
            context.app.templateParsed[templateName] = templateParse(
              context.app.template[templateName]
            );
          });
        }
      }
    }
  }
}

export const getTemplateParsedById = (context, templateId) => {
  const templateMetadata = context.app?.templateMetadata;
  if (!templateMetadata) return null;
  let templateName = null;
  for (const key in templateMetadata) {
    if (templateMetadata.hasOwnProperty(key)) {
      if (templateMetadata[key] === templateId) {
        templateName = key;
      }
    }
  }
  if (!templateName) return templateName;
  const templateParsed = context.app?.templateParsed || {};
  if (templateParsed[templateName]) return templateParsed[templateName];
  const templates = context.app?.template;
  if (templates[templateName]) return templates[templateName];
  return null;
};
