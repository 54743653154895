//import { waitingToGetElement } from '../utils';

export const setEventComponentToElement = async (eventFunc, element, type) => {
  // const optionElements = await waitingToGetElement(element);
  const optionElements = {};
  if (optionElements && eventFunc) {
    const currency = getObjectCurrency(type).currentCurrency;
    eventFunc({ target: { dataset: { value: currency } } });

    if (optionElements?.[0].classList[0] === 'currency-switcher' && !optionElements?.[1]) {
      optionElements[0].addEventListener('change', (event) => {
        eventFunc({ target: { dataset: { value: event.target?.value } } });
      });
    } else {
      optionElements.forEach((element) => {
        if (element.classList.contains('currency-switcher')) return;

        element.addEventListener('click', eventFunc);
      });
    }
  }
};

export const getObjectCurrency = (type) => {
  let objectCurrency = null;

  if (type === 'auto') objectCurrency = window.Currency ?? window.ACSCurrency;
  if (type === 'best') objectCurrency = window.DoublyGlobalCurrency;

  return objectCurrency;
};

export const getRateCurrency = (type) => (currency) => {
  const DEFAULT_RATE = 1;
  const objectCurrency = getObjectCurrency(type);

  if (objectCurrency) {
    const translate = objectCurrency.rates?.[currency || objectCurrency.currentCurrency];
    const main = objectCurrency.rates?.[window.boostSDAppConfig?.shop?.currency];

    if (!translate || !main) return DEFAULT_RATE;

    return main / translate;
  }

  return DEFAULT_RATE;
};

export const getUnitCurrency = (type) => (currency) => {
  const objectCurrency = getObjectCurrency(type);

  if (objectCurrency) {
    return objectCurrency.moneyFormats[currency || objectCurrency.currentCurrency]?.money_format;
  }

  return null;
};
