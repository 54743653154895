import { Liquid } from 'liquidjs';
const engine = new Liquid();

engine.registerFilter('currency_format', function (money, format) {
  function defaultOption(opt, def) {
    return typeof opt == 'undefined' ? def : opt;
  }
  const stripHtml = (string) => {
    return string ? string.toString().replace(/<.*?>/gi, '').replace('&#36;', '') : string;
  };

  const replaceDotCommaZero = (str) => {
    // replace end string ,00 or .00 -> empty
    console.log('str', str?.replace(/[\.,]00$/, ''));
    return str?.replace(/[\.,]00$/, '');
  };

  function formatWithDelimiters(number, precision, thousands, decimal) {
    if (number !== 0 && !number) return '';

    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, ',');
    decimal = defaultOption(decimal, '.');

    number = parseFloat(`${number}`).toFixed(precision);

    const parts = replaceDotCommaZero(number.toString()).split('.');
    const dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands);
    const money = parts[1] ? decimal + parts[1] : '';
    const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
    let formatString = format || '${{amount}}';
    const moneyFormatted = stripHtml(formatString).replace(placeholderRegex, dollars + money);

    return moneyFormatted;
  }

  switch (format) {
    case 'amount':
      return formatWithDelimiters(money, 2);
    case 'amount_no_decimals':
      return formatWithDelimiters(money, 0);
    case 'amount_with_comma_separator':
      return formatWithDelimiters(money, 2, '.', ',');
    case 'amount_no_decimals_with_comma_separator':
      return formatWithDelimiters(money, 0, '.', ',');
    case 'amount_with_space_separator_no_comma':
      return formatWithDelimiters(money, 2);
    case 'amount_no_decimals_with_space_separator':
      return formatWithDelimiters(money, 0, ' ', '.');
    default:
      return formatWithDelimiters(money, 2);
  }
});
export function translate(key, translations, defaultLabel) {
  if (!translations || typeof translations !== 'object' || !key) {
    return defaultLabel;
  }
  const keys = key.split('.');
  let translation = translations;
  for (const k of keys) {
    if (translation.hasOwnProperty(k)) {
      translation = translation[k];
    } else {
      return defaultLabel;
    }
  }
  if (typeof translation !== 'string') return defaultLabel;
  return translation !== undefined ? translation : defaultLabel;
}
engine.registerFilter('translate', translate);
export const paginationListFunction = (page, totalPage) => {
  if (totalPage < 6) {
    return [...Array(totalPage).keys()].map((i) => i + 1);
  }

  if (page < 5) {
    // 0 present for ...
    return [1, 2, 3, 4, 5, 0, totalPage];
  }

  if (totalPage - page < 4) {
    return [1, 0, totalPage - 4, totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
  }

  return [1, 0, page - 1, page, page + 1, 0, totalPage];
};
function productMetafield(product, metafieldInfo) {
  if (typeof product !== 'object') return '';
  if (!product.metafields || product?.metafields.length === 0) return '';
  const [namespace, key] = metafieldInfo.split('.');
  const metafield = product.metafields.find(
    (metafield) => metafield.namespace == namespace && metafield.key == key
  );
  if (metafield) return metafield.value;
  const metafieldWithoutKey = product.metafields.find(
    (metafield) => metafield.namespace == namespace
  );
  if (metafieldWithoutKey) return metafieldWithoutKey.value;
  return '';
}

function jsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.log(error);
    return str;
  }
}
function compare(str1, str2) {
  str1 = String(str1);
  str2 = String(str2);
  return str1 === str2;
}

engine.registerFilter('json_parse', jsonParse);
engine.registerFilter('compare', compare);

engine.registerFilter('pagination_list', function (page, totalPage) {
  return paginationListFunction(page, totalPage).join(',');
});
engine.registerFilter('product_metafield', productMetafield);
export const templateRender = (templateString, data) => {
  try {
    if (typeof templateString === 'string') {
      const template = engine.parseAndRenderSync(templateString, {
        ...data,
        data,
      });
      return template;
    } else {
      const template = engine.renderSync(templateString, {
        ...data,
        data,
      });
      return template;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const templateParse = function (templateString) {
  return engine.parse(templateString);
};
