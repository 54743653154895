export function sortByKey(data, key, order = 'asc') {
  if (!Array.isArray(data) && typeof data !== 'object') {
    console.error('Invalid input. Expecting an array or an object.');
    return;
  }

  if (Array.isArray(data)) {
    return sortArrayByKey(data, key, order);
  } else {
    return sortObjectByKey(data, key, order);
  }
}

function sortArrayByKey(array, key, order) {
  const sortOrder = order === 'desc' ? -1 : 1;

  return array.sort((a, b) => {
    const aValue = a[key];
    const bValue = b[key];

    if (aValue < bValue) {
      return -1 * sortOrder;
    } else if (aValue > bValue) {
      return 1 * sortOrder;
    } else {
      return 0;
    }
  });
}

function sortObjectByKey(object, key, order) {
  const entries = Object.entries(object);

  const sortedEntries = sortArrayByKey(entries, key, order);

  const result = {};
  for (const [key, value] of sortedEntries) {
    result[key] = value;
  }

  return result;
}

export function isEqual(value1, value2) {
  if (typeof value1 !== typeof value2) {
    return false;
  }

  if (typeof value1 === 'object' && value1 !== null && value2 !== null) {
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!isEqual(value1[key], value2[key])) {
        return false;
      }
    }

    return true;
  }

  return value1 === value2;
}

export function deepClone(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    const newArray = [];
    for (let i = 0; i < obj.length; i++) {
      newArray[i] = deepClone(obj[i]);
    }
    return newArray;
  }

  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = deepClone(obj[key]);
    }
  }
  return newObj;
}
