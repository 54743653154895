import {
  addParamsLocale,
  getCurrentPage,
  stripHtml,
  getSearchSettings,
  setLocalStorage,
} from '../utils';
import { saveRequestId } from '.';
import { QUERY_STRING_KEY } from '../constants/app';

const buildQueryParams = (params) => {
  const queryParams = new URLSearchParams();
  queryParams.sort();
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryParams.append(`${key}`, item);
        });
      } else {
        queryParams.set(key, value);
      }
    }
  }
  return queryParams;
};

export const getProductsDetail = async (context, ids) => {
  try {
    const url = context.app.searchUrl;
    const searchSettings = getSearchSettings(context);
    const { productAvailable } = searchSettings;
    let params = {
      shop: window.Shopify?.shop,
      locale: context.app?.generalSettings?.current_locale || 'en',
      event_type: 'init',
      ids,
    };

    if (productAvailable) {
      params.product_available = productAvailable;
    }

    if (context.app.b2b?.enabled) {
      params.company_location_id = `${context.app.b2b.current_company_id}_${context.app.b2b.current_location_id}`;
    }

    params = addParamsLocale(params);

    const response = await fetch(`${url}/products?${buildQueryParams(params)}`, {
      method: 'GET',
    });
    return response.json();
  } catch (error) {
    console.log('Can not get list suggestion product');
    return {};
  }
};

export const getProductsDetailByHandle = async (context, handle, searchQuery) => {
  try {
    const url = context.app.searchUrl;
    const searchSettings = getSearchSettings(context);
    const { productAvailable } = searchSettings;
    let params = {
      shop: window.Shopify?.shop,
      handle,
      locale: context.app?.generalSettings?.current_locale || 'en',
      event_type: 'init',
    };

    if (productAvailable) {
      params.product_available = productAvailable;
    }

    if (context.app.b2b?.enabled) {
      params.company_location_id = `${context.app.b2b.current_company_id}_${context.app.b2b.current_location_id}`;
    }

    const response = await fetch(`${url}/products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    return response.json();
  } catch (error) {
    console.log('Can not get list suggestion product');
  }
};

export const getMostPopularProductTemplate = async (context, handle) => {
  try {
    const url = context.app.searchUrl;
    url?.replace('search', 'ssr-template');

    const widgetName = 'mostPopularProducts';
    const widgetId = context?.app?.templateMetadata?.[widgetName];
    const themeLib = context?.app?.templateMetadata?.themeNameLib || 'default';

    let params = {
      shop: window.Shopify?.shop,
      locale: context.app?.generalSettings?.current_locale || 'en',
      event_type: 'init',
      handle,
      widget: `${widgetName}:${widgetId}`,
      theme_lib: themeLib,
    };

    if (context.app.b2b?.enabled) {
      params.company_location_id = `${context.app.b2b.current_company_id}_${context.app.b2b.current_location_id}`;
    }

    const response = await fetch(`${url}/products?${buildQueryParams(params)}`, {
      method: 'GET',
    });
    return response.json();
  } catch (error) {
    console.log('Can not get mostPopularProducts template', error);
    return {};
  }
};

export const getSuggestionSearch = async (context, query, customizeSearchParams = {}) => {
  try {
    const url = context.app.searchUrl;
    const {
      suggestionDymLimit,
      enableDefaultResult,
      enableFuzzySearch,
      productAvailable,
      suggestionMode,
      skipFields,
      reduceMinMatch,
      fullMinMatch,
      enablePlusCharacterSearch,
      suggestionBlocks,
      showVariantsAsProduct,
      locale,
      translateSearchParams,
    } = context.app?.searchSettings;

    let params = {
      t: Date.now(),
      shop: window.Shopify?.shop,
      locale: context.app?.generalSettings?.current_locale || 'en',
      q: stripHtml(query),
      // sid: getBoostPFSSectionId(),
      re_run_if_typo: true, // field did you mean improve
      event_type: 'suggest',
      pg: getCurrentPage(),
    };

    params = addParamsLocale(params);

    // limit
    if (Array.isArray(suggestionBlocks)) {
      // { type: 'suggestions', label: 'Suggestions', status: 'active', number: 3 },
      suggestionBlocks.forEach((block) => {
        const id = block['type'].slice(0, -1);
        params[`${id}_limit`] = block['number'];
      });
    }

    if (suggestionMode) params.suggestionMode = suggestionMode;
    if (skipFields && skipFields.length > 0) params.skipFields = skipFields;
    if (suggestionDymLimit) params['dym_limit'] = suggestionDymLimit;
    if (enableFuzzySearch !== true) params.fuzzy = enableFuzzySearch;
    if (reduceMinMatch !== false) params.reduce_min_match = reduceMinMatch;
    if (fullMinMatch) params.full_min_match = true;
    if (enablePlusCharacterSearch) params.enable_plus_character_search = true;
    if (!enableDefaultResult) params.enable_default_result = false;
    // Show variants as products
    if (showVariantsAsProduct) params.variants_as_products = true;
    // Get product/variant available or not field. ! of Fields Out of stock products in instant search
    if (productAvailable) params.product_available = true;
    if (context.app.b2b?.enabled) {
      params.company_location_id = `${context.app.b2b.current_company_id}_${context.app.b2b.current_location_id}`;
    }
    // allow customize search params with any params
    if (customizeSearchParams && Object.keys(customizeSearchParams)?.length > 0) {
      params = {
        ...params,
        ...customizeSearchParams,
      };
    }

    const response = await fetch(`${url}/suggest?${buildQueryParams(params)}`, {
      method: 'GET',
    });

    const resJson = await response.json();

    // TODO Check when api return meta rid
    const requestId = await resJson.meta?.rid;
    saveRequestId('suggest', requestId);
    setLocalStorage(QUERY_STRING_KEY, `q=${stripHtml(query)}`);

    //Generate newId for customize
    const newId = window.boostWidgetIntegration.generateUuid();
    context.state.latestInstantSearchRequest = newId;

    return resJson;
  } catch (error) {
    console.log('Can not get suggestion data', error);
    return { query };
  }
};

export const getRedirects = async (context, terms = []) => {
  try {
    const url = context.app.searchUrl;
    let params = {
      t: Date.now(),
      shop: window.Shopify?.shop,
      locale: context.app?.generalSettings?.current_locale || 'en',
      build_filter_tree: true,
      // sid: getBoostPFSSectionId(),
      pg: getCurrentPage(),
      terms,
      limit: 0, // not limit record from API redirects.
    };

    if (context.app.b2b?.enabled) {
      params.company_location_id = `${context.app.b2b.current_company_id}_${context.app.b2b.current_location_id}`;
    }
    params = addParamsLocale(params);

    const response = await fetch(`${url}/redirects?${buildQueryParams(params)}`, {
      method: 'GET',
    });

    const responseData = await response.json();
    let redirects = {};

    responseData.data?.forEach((redirect) => {
      redirect?.terms?.forEach((term) => {
        redirects[term] = redirect.redirect_to;
      });
    });
    return redirects;
  } catch (error) {
    console.log('Can not get list of redirect');
    return {};
  }
};
