const adjustTooltipPosition = (tooltipContent, rootElement) => {
  if (!tooltipContent || !rootElement) return;

  const position = rootElement.getBoundingClientRect();

  const slideAsParent = tooltipContent.closest('.slick-slide');

  if (slideAsParent) {
    const { height: tooltipHeight, y: tooltipY } = rootElement.getBoundingClientRect();
    const { y: slideY } = slideAsParent.getBoundingClientRect();
    const transformY = tooltipY - slideY < tooltipHeight ? '35px' : '-35px';

    let transform = `translate(calc(-50% + ${position.width / 2}px), ${transformY})`;

    Object.assign(tooltipContent.current.style, {
      position: 'fixed',
      display: 'block',
      transform,
    });

    const { width: tooltipWidth, x: tooltipX } = tooltipContent.current.getBoundingClientRect();

    if (slideAsParent.classList.contains('slick-current')) {
      const { x: slideX } = slideAsParent.getBoundingClientRect();
      if (tooltipX - slideX < tooltipWidth) {
        transform = `translate(0px, ${transformY})`;
      }
    }

    if (
      slideAsParent.classList.contains('slick-active') &&
      !slideAsParent.nextElementSibling?.classList.contains('slick-active')
    ) {
      const { x: slideX, width: slideWidth } = slideAsParent.getBoundingClientRect();
      if (slideX + slideWidth - tooltipX < tooltipWidth) {
        transform = `translate(-50%, ${transformY})`;
      }
    }

    Object.assign(tooltipContent.current.style, {
      position: 'fixed',
      display: 'block',
      transform,
    });

    return;
  }

  let left = `${position.left}px`;
  let right = `${position.right}px`;
  const transform = `translate(calc(-50% + ${position.width / 2}px), calc(-100% - 6px))`;

  Object.assign(tooltipContent.style, {
    position: 'fixed',
    left,
    top: `${position.top}px`,
    display: 'block',
    transform,
  });

  const positionContent = tooltipContent?.getBoundingClientRect();

  if (positionContent.width / 2 > position.left) {
    left = `${positionContent.width / 2}px`;
    Object.assign(tooltipContent.style, {
      left,
    });
  }

  if (
    position.right + positionContent.width / 2 > window.innerWidth &&
    position.width < positionContent.width
  ) {
    right = `${position.width / 2 - positionContent.width / 2 + 5}px`;
    left = `auto`;
    Object.assign(tooltipContent.style, {
      left,
      right,
    });
  }
};

export const handleTooltip = (context) => {
  const tooltips = document.querySelectorAll('.boost-sd__tooltip');

  tooltips?.forEach((tooltip) => {
    tooltip?.addEventListener('mouseenter', () => {
      const tooltipContent = tooltip?.querySelector('.boost-sd__tooltip-content');
      adjustTooltipPosition(tooltipContent, tooltip);
    });

    tooltip?.addEventListener('mouseleave', () => {
      const tooltipContent = tooltip?.querySelector('.boost-sd__tooltip-content');
      if (tooltipContent) {
        Object.assign(tooltipContent.style, {
          display: 'none',
        });
      }
    });
  });
};
