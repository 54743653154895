import { buildQueryParams } from '../utils/index.js';
const EXPIRED = 86400000; // 1day

/**
 * get templates by id and name
 * @param context
 * @param themeNameLib
 * @param widgets {[{name: string, id: (*|string)}]}[]
 * @returns { templates: Dict }
 */
export const getTemplateByIds = async (context, themeNameLib = 'default', widgets) => {
  const url = context.app.templateUrl;

  const widgetCache = getTemplateFromLocalStorage(context, widgets);
  const noneCache = widgets.filter((widget) => !widgetCache[widget.id]);
  if (noneCache.length === 0) {
    return {
      templates: widgetCache,
    };
  }

  const params = {
    shop: window.Shopify?.shop,
    theme_lib: themeNameLib,
    widgets: noneCache.map((widget) => `${widget.name}:${widget.id}`),
  };

  const response = await fetch(`${url}?${buildQueryParams(params)}`, {
    method: 'GET',
  });
  const templates = (await response.json()).templates;
  Object.keys(templates).forEach((id) => {
    const key = generateLocalStorageKey(id);
    const data = JSON.stringify({
      time: Date.now(),
      template: templates[id],
    });
    try {
      localStorage.setItem(key, data);
    } catch (error) {
      removeLocalStorageByPrefix('WIDGET:');
      localStorage.setItem(key, data);
    }
  });
  return {
    templates: {
      ...widgetCache,
      ...templates,
    },
  };
};

const getTemplateFromLocalStorage = (context, widgets) => {
  const widgetCache = {};
  const themeUpdatedAt = context?.app?.templateMetadata?.updatedAt || 0;
  widgets.forEach((widget) => {
    const key = generateLocalStorageKey(widget.id);
    const dataString = localStorage.getItem(key);
    if (dataString) {
      const data = JSON.parse(dataString);
      const isExpired = Date.now() - data.time > EXPIRED;
      const isOutDate = data.time / 1000 < themeUpdatedAt; // themeUpdatedAt is millisecond
      if (isExpired || isOutDate) {
        localStorage.removeItem(key);
      } else {
        widgetCache[widget.id] = data.template;
      }
    }
  });

  return widgetCache;
};

const generateLocalStorageKey = (id) => `WIDGET:${id}`;

const removeLocalStorageByPrefix = (prefix) => {
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    if (key.startsWith(prefix)) {
      localStorage.removeItem(key);
    }
  }
};
