export { sortByKey, isEqual, deepClone } from './object';
export * from './params';
export * from './filter-tree';
export * from './validate';
export * from './format';
export * from './browser';
export * from './dom';
export * from './object';
export * from './params';
export * from './event';
export * from './animation';
export * from './settings';
export * from './recentlyViewedProduct';
export * from './translation';

/**
 * Safely parses a JSON string, catching and logging any errors.
 * Returns the parsed JSON object, or null if parsing fails.
 */
export const safeParseJSON = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error parsing JSON:', error, jsonString);
    return null;
  }
};

/**
 * Generates an MD5 hash for the given input string.
 *
 * Uses bitwise operations and rotations to hash the
 * input string one character at a time.
 */
export function simpleMd5(input) {
  const inputLength = input.length;

  let a = 0x67452301;
  let b = 0xefcdab89;
  let c = 0x98badcfe;
  let d = 0x10325476;

  for (let i = 0; i < inputLength; i++) {
    a = a ^ input.charCodeAt(i);

    a = (a >>> 7) | (a << (32 - 7));

    c = (c + a + b) & 0xffffffff;
    b = (b >>> 17) | (b << (32 - 17));
    d = (d + c + a) & 0xffffffff;
    a = (a >>> 19) | (a << (32 - 19));

    b = b ^ input.charCodeAt(i);

    b = (b >>> 10) | (b << (32 - 10));

    c = (c + b + d) & 0xffffffff;
    d = (d >>> 22) | (d << (32 - 22));
    a = (a + c + b) & 0xffffffff;
    b = (b >>> 23) | (b << (32 - 23));
  }

  let md5Result =
    'w-' +
    [a, b, c, d]
      .map(function (i) {
        return i.toString(16).padStart(2, '0');
      })
      .join('');
  if (md5Result.startsWith('-')) {
    md5Result = md5Result.slice(1);
  }

  return md5Result;
}

/**
 * Scrolls the window to the top.
 * @param behavior "auto" | "smooth"
 * @param isFilter true | false
 * boostSdDisableScrollToTop - disabled scroll to top after Filter
 * window.boostSdScrollTopSticky - sticky menu and toolbar ...
 *
 */
export function scrollToTop({ behavior = 'smooth', isFilter = false } = {}) {
  let top = 0;

  if (
    window.boostSdDisableScrollToTop ||
    (window.boostSdDisableScrollToTopAfterFiltered && isFilter)
  ) {
    return;
  }

  if (isFilter) {
    const layout = document.querySelector('.boost-sd-right');
    if (!layout) return;

    const layoutTop = layout.getBoundingClientRect()?.top;
    const _top = layoutTop + window.scrollY - (window.boostSdScrollTopSticky || 150);

    if (window.scrollY > layoutTop && _top > 0) {
      top = _top;
    }
  }

  window.scrollTo({
    top: top,
    behavior,
  });
}

/**
 * Waits for the given number of milliseconds before resolving the promise.
 * Useful for delaying or throttling code execution.
 *
 * @param {number} ms - Number of milliseconds to wait before resolving the promise.
 */
export const waitFor = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export const stripHtml = (string) => {
  return string?.toString()?.replace(/<.*?>/gi, '').replaceAll('&#36;', '$');
};

export const buildUrlWithLocalization = (url) => {
  if (!url) return '/';

  const routesRoot = window?.Shopify?.routes?.root || '/';

  return `${routesRoot}${url}`;
};

export const loadResource = (script, position = 'body', keySource = 'src') => {
  return new Promise((resolve, reject) => {
    script.onload = function () {
      resolve(true);
    };

    script.onerror = function (error) {
      reject(error);
    };

    switch (position) {
      case 'head': {
        document.head.appendChild(script);
      }

      case 'body': {
        document.body.appendChild(script);
      }
    }
  });
};

export const slugify = (text) => {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim()
    .replace(/[\s/]+/g, '-') // Replace spaces with -
    .replace(/[`~!@#$%^&*()|+\-=?;:'",.<>{}[\]\\/]/g, '-') // Replace all special characters with -
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

/**
 * Get file path
 * @param {String} fileName The file mane
 * @param {*} extension The file extension
 * @param {*} version The version of file
 */
export const getFilePath = (fileName, extension, version) => {
  var extension = typeof extension !== 'undefined' ? extension : 'png';
  var version = typeof version !== 'undefined' ? version : '';
  const defaultFileUrl = '//cdn.shopify.com/s/files/1/0663/1463/3470/files/?10045';
  var filePath = defaultFileUrl.split('?')[0];
  filePath += fileName + '.' + extension + (version ? '?v=' + version : '');
  return filePath;
};

export const getProductMetafield = (productData, namespace, key) => {
  const metafieldValue = productData?.metafields?.filter(function (item) {
    return item?.namespace === namespace && item.key === key;
  });

  return metafieldValue?.[0]?.value ?? '';
};
