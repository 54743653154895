import { CartAPI } from '../api/cart';
import { getTemplate } from '../template';
import { templateRender } from '../template/templateRender';
import { buildUrlWithLocalization, formatCurrency, setWindowLocation } from '../utils';
import { getThemeSettings } from '../utils/settings';
import { getCartSelectorAndUpdateAction } from './selectors';

export const handleCart = (context) => {
  const { enableCart } = getThemeSettings(context)?.cart || {};

  const hasProductElm = context.document?.querySelector(
    '.boost-sd__product-item, .boost-sd__recommendation-bundle'
  );

  if (!enableCart || !hasProductElm) return;

  const [getCart, setCart] = context.useContextState('cart', {
    isShow: false,
  });

  const [getForceRender, setForceRender] = context.useContextState('force-render', false);

  const item = getCartSelectorAndUpdateAction();

  if (!item || item?.selector === null) console.error('Cart icon is not found');

  const cartIcon = document.querySelector(item?.selector);

  cartIcon?.addEventListener('click', (e) => {
    e.preventDefault();

    setCart({
      isShow: !getCart().isShow,
    });
  });

  // Listen to custom event to open cart
  document.addEventListener('boost-open-cart', () => {
    setCart({
      isShow: true,
    });
  });

  context.render(async () => {
    const cart = getCart();

    const initOnClickOutSide = (e) => {
      const closeBtn = document.querySelector('.boost-sd__modal-close-btn');
      const cb = () => {
        setCart({
          isShow: false,
        });
      };

      outsideClickListener(document.querySelector('.boost-sd__cart'), e, cb, closeBtn);
    };

    if (cart && cart.isShow) {
      const { html, itemCount } = await renderCart(context);

      const modal = document.querySelector('.boost-sd__modal');
      if (modal) {
        document.body.removeChild(modal);
      }

      document.body.insertAdjacentHTML('beforeend', html);
      document.body.classList.add('modal-open-cart');

      item?.action(itemCount);

      const cartItems = document.querySelectorAll('.boost-sd__cart-item');
      const cartBtns = document.querySelectorAll('.boost-sd__cart-btn');
      const viewCartBtn = document.querySelector('.boost-sd__btn-view-cart');
      const checkOutBtn = document.querySelector('.boost-sd__btn-checkout');

      const closeBtn = document.querySelector('.boost-sd__modal-close-btn');
      const cartItemCount = document.querySelector('.boost-sd__cart-items-count');

      cartItems?.forEach((cartItem) => {
        const buttons = cartItem.querySelectorAll('.boost-sd__input-number-btn');
        const decreaseQuantityBtn = buttons?.[0];
        const increaseQuantityBtn = buttons?.[1];
        const quantityInput = cartItem.querySelector('.boost-sd__input-number-input');
        const removeItemBtn = cartItem.querySelector('.boost-sd__cart-item-remove-btn');
        const productId = cartItem.getAttribute('data-product-id');

        decreaseQuantityBtn?.addEventListener('click', async () => {
          await CartAPI.change(productId, Number(quantityInput.value) - 1);
          setForceRender(!getForceRender());
        });
        increaseQuantityBtn?.addEventListener('click', async () => {
          await CartAPI.change(productId, Number(quantityInput.value) + 1);
          setForceRender(!getForceRender());
        });
        removeItemBtn?.addEventListener('click', async () => {
          await CartAPI.change(productId, 0);
          setForceRender(!getForceRender());
        });

        quantityInput.addEventListener('change', async (e) => {
          const value = e.target.value;

          if (Number.isInteger(Number(value)) && Number(value) > 0) {
            await CartAPI.change(productId, Number(value));
            setForceRender(!getForceRender());
          }
        });
      });

      viewCartBtn?.addEventListener('click', () => {
        setWindowLocation(buildUrlWithLocalization('cart'));
      });

      checkOutBtn?.addEventListener('click', () => {
        setWindowLocation('/checkout');
      });

      closeBtn?.addEventListener('click', () => {
        setCart({
          ...cart,
          isShow: false,
        });
      });

      document.querySelector('.boost-sd__modal')?.addEventListener('click', initOnClickOutSide);
    } else {
      const closeBtn = document.querySelector('.boost-sd__modal-close-btn');
      const cartBtns = document.querySelectorAll('.boost-sd__cart-btn');

      if (closeBtn) {
        closeBtn.removeEventListener('click', closeCartModal);
      }
      document.querySelector('.boost-sd__modal')?.removeEventListener('click', initOnClickOutSide);

      document.querySelector('.boost-sd__modal')?.remove();
      document.body.classList.remove('modal-open-cart');
    }
  }, ['cart', 'force-render']);
};

export const addToCart = async (context, item, target, onSuccess, onFail) => {
  const [getCart, setCart] = context.useContextState('cart', {
    isShow: false,
  });

  const { productId, quantity = 1 } = item;

  const ctaBtnText = target.querySelector('.boost-sd__cta-button-text');
  const element = ctaBtnText || target;

  element.textContent =
    context.app?.translation?.productItem?.atcAddingToCartBtnLabel || 'Adding...';
  element.setAttribute('disabled', 'true');

  try {
    const res = await CartAPI.add(productId, quantity);
    if (res.status === 200) {
      element.textContent =
        context.app?.translation?.productItem?.atcAddedToCartBtnLabel || 'Added!';

      const { enableCart } = getThemeSettings(context)?.cart || {};

      if (enableCart) {
        setCart({ isShow: true });
      } else {
        setWindowLocation('/cart');
      }

      if (onSuccess) setTimeout(onSuccess, 300);
    } else {
      element.textContent =
        context.app?.translation?.productItem?.atcFailedToCartBtnLabel || 'Failed!';
      if (onFail) setTimeout(onFail, 300);
    }
  } catch {
    element.textContent =
      context.app?.translation?.productItem?.atcFailedToCartBtnLabel || 'Failed!';
    if (onFail) setTimeout(onFail, 300);
  }

  element.removeAttribute('disabled');

  setTimeout(
    () =>
      (element.textContent =
        context.app?.translation?.productItem?.atcAvailableLabel || 'Add to cart'),
    500
  );
};

export const addToCartRecommendBundle = async (context, data, target, onSuccess, onFail) => {
  const [getCart, setCart] = context.useContextState('cart', {
    isShow: false,
  });

  const ctaBtnText = target.querySelector('.boost-sd__btn-add-to-cart');
  const element = ctaBtnText || target;

  element.textContent =
    context.app?.translation?.productItem?.atcAddingToCartBtnLabel || 'Adding...';
  element.setAttribute('disabled', 'true');

  try {
    const res = await CartAPI.addMultiProductToCart(data);
    if (res.status === 200) {
      element.textContent =
        context.app?.translation?.productItem?.atcAddedToCartBtnLabel || 'Added!';

      const { enableCart } = getThemeSettings(context)?.cart || {};

      if (enableCart) {
        setCart({ isShow: true });
      } else {
        setWindowLocation('/cart');
      }

      if (onSuccess) onSuccess();
    } else {
      element.textContent =
        context.app?.translation?.productItem?.atcFailedToCartBtnLabel || 'Failed!';
      if (onFail) onFail();
    }
  } catch {
    element.textContent =
      context.app?.translation?.productItem?.atcFailedToCartBtnLabel || 'Failed!';
    if (onFail) onFail();
  }

  element.removeAttribute('disabled');

  setTimeout(
    () =>
      (element.textContent =
        context.app?.translation?.productItem?.atcAvailableLabel || 'Add to cart'),
    500
  );
};

const outsideClickListener = (element, event, callback, ignore) => {
  if (!element?.contains(event.target) && !ignore?.contains(event.target)) {
    callback(event);
  }
};

const closeCartModal = () => {
  document.querySelector('.boost-sd__modal').remove();
  document.body.classList.remove('modal-open-cart');
};

export const renderCart = async (context) => {
  const data = await CartAPI.getAll();

  const {
    cartStyle = 'side',
    generalLayout: { shape = 'round' },
  } = getThemeSettings(context)?.cart || {};

  let html = '';

  data?.items.forEach((item) => {
    item.price = formatCurrency({ context, value: (item?.price || 0) / 100 });
    item.options_with_values = item?.options_with_values?.filter((option) => {
      return option.name !== 'Title' && option.value !== 'Default Title';
    });
  });

  html += context.templateRender(getTemplate(context).cartTemplate, {
    items: data.items,
    item_count: data.item_count,
    items_subtotal_price: formatCurrency({
      context,
      value: (data?.items_subtotal_price || 0) / 100,
    }),
    cartStyle,
    shape,
  });

  return {
    html,
    itemCount: data.item_count,
  };
};
