export const productPriceTemplate = `
{% if compareAtPricePosition == 'top' or compareAtPricePosition == 'bottom' %}
  <div
    class="boost-sd__product-price-content 
    boost-sd__product-price-content--column{% if compareAtPricePosition == 'top' %}-reverse{% endif %}"
  >
    <div class="boost-sd__product-price-content boost-sd__product-price-content--text-align-{{ textAlign }}">
      <span
        class="{% if isSale %}boost-sd__product-price--sale{% else %}boost-sd__product-price--default{% endif %}">
        <span class="boost-sd__format-currency">{{ priceValueWithFormat }}</span></span
      >
      
      {% if savingPriceWithFormat %}
        <span class="boost-sd__product-price--saving"><span class="boost-sd__format-currency">{{ savingPriceWithFormat }}</span></span>
      {% endif %}
    </div>

    {% if compareAtPriceWithFormat %}
      <div class="boost-sd__product-price--compare">
        <span
          class="boost-sd__format-currency boost-sd__format-currency--price-compare"
        >
          <span class="">{{ compareAtPriceWithFormat }}</span>
        </span>
      </div>
    {% endif %}
  </div>

{% else %}
  <div class="boost-sd__product-price-wrapper">
    <span
      class="boost-sd__product-price-content {% if compareAtPricePosition == 'left' %}boost-sd__product-price-content--row-reverse{% endif %}  boost-sd__product-price-content--text-align-{{ textAlign }}"
    >
      <span
        class="{% if isSale %}boost-sd__product-price--sale{% else %}boost-sd__product-price--default{% endif %}"
        >
        <span class="boost-sd__format-currency">{{ priceValueWithFormat }}</span>
      </span>
      
      {% if compareAtPriceWithFormat %}
        <span
          class="boost-sd__format-currency boost-sd__format-currency--price-compare boost-sd__product-price--compare"
          > {{ compareAtPriceWithFormat }}
        </span>
      {% endif %}
    </span>

    {% if savingPriceWithFormat %}
      <span class="boost-sd__product-price--saving"><span class="boost-sd__format-currency">{{ savingPriceWithFormat }}</span></span>
    {% endif %}
  </div>
{% endif %}`;
