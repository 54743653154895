export const searchTabCollectionsTemplate = `<div class="boost-sd__search-result-panel-content">
{% for collection in collections %}
  <a href="/collections/{{ collection.handle }}" class="boost-sd__search-result-panel-content-item">

    {% if displayImage %}
      {% if collection.image.src %}
        <div class="boost-sd__search-result-panel-content-item-image">
          <img src="{{ collection.image.src }}">
        </div>
      {% endif %}
    {% endif %}

    <div class="boost-sd__search-result-panel-content-item-text">
      <p class="boost-sd__search-result-panel-content-item-title">{{ collection.title }}</p>

      {% if displayDescription and collection.body_html != "" %}
        <div class="boost-sd__search-result-panel-content-item-description">{{ collection.body_html }}</div>
      {% endif %}
    </div>
  </a>
{% endfor %}


{% assign pageSize = pageSize | plus: 0 %}

{% if pageSize  < totalCollection %}
{% assign totalPage = totalCollection | divided_by: pageSize | ceil %}

{% assign pagination = currentPage | pagination_list: totalPage | split: "," %}
{% assign to_product = currentPage | times: pageSize %}
<div class="boost-sd__pagination boost-sd__pagination--{{ paginationAlignment }}">
  {% if currentPage != 1 %}
    <button
      type="button"
      aria-label="previous page"
      class="boost-sd__pagination-button boost-sd__pagination-button--{{ paginationButtonShape }} boost-sd__pagination-button--previous"
      style="color: rgb(122, 122, 122); background-color: rgba(0, 0, 0, 0); font-size: inherit; text-transform: none; border-color: transparent; border-width: 1px; border-style: solid;"
    >
      <span class="boost-sd__pagination-button-icon boost-sd__pagination-button-icon--left">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 7 12" height="12" width="7">
          <path d="M6.80474 0.528514C6.54439 0.268165 6.12228 0.268165 5.86193 0.528514L0.861929 5.52851C0.601579 5.78886 0.601579 6.21097 0.861929 6.47132L5.86193 11.4713C6.12228 11.7317 6.54439 11.7317 6.80474 11.4713C7.06509 11.211 7.06509 10.7889 6.80474 10.5285L2.27614 5.99992L6.80474 1.47132C7.06509 1.21097 7.06509 0.788864 6.80474 0.528514Z" clip-rule="evenodd" fill-rule="evenodd"></path>
        </svg>
      </span>
    </button>
  {% endif %}
  {% for page_string in pagination %}
    {% assign page = page_string | plus: 0 %}
    {% if page == 0 %}
        <button class="boost-sd__pagination-number boost-sd__pagination-number--disabled boost-sd__pagination-number--{{ paginationNumberShape }}">...</button>
    {% elsif page == currentPage %}
        <button class="boost-sd__pagination-number boost-sd__pagination-number--{{ paginationNumberShape }} boost-sd__pagination-number--active" style="color: rgb(122, 122, 122); background-color: rgba(0, 0, 0, 0); font-size: inherit;" data-page={{ page }}>{{ page }}</button>
    {% else %}
        <button class="boost-sd__pagination-number boost-sd__pagination-number--{{ paginationNumberShape }}" style="color: rgb(122, 122, 122); background-color: rgba(0, 0, 0, 0); font-size: inherit;" data-page={{ page }}>{{ page }}</button>     
    {% endif %}
  {% endfor %}
  {% if currentPage != totalPage %}
    <button
      type="button"
      aria-label="next page"
      class="boost-sd__pagination-button boost-sd__pagination-button--{{ paginationButtonShape }} boost-sd__pagination-button--next"
      style="color: rgb(122, 122, 122); background-color: rgba(0, 0, 0, 0); font-size: inherit; text-transform: none; border-color: transparent; border-width: 1px; border-style: solid;"
    >
      <span class="boost-sd__pagination-button-icon boost-sd__pagination-button-icon--right">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 7 12" height="12" width="7">
          <path
            d="M0.195262 0.528514C0.455612 0.268165 0.877722 0.268165 1.13807 0.528514L6.13807 5.52851C6.39842 5.78886 6.39842 6.21097 6.13807 6.47132L1.13807 11.4713C0.877722 11.7317 0.455612 11.7317 0.195262 11.4713C-0.0650874 11.211 -0.0650874 10.7889 0.195262 10.5285L4.72386 5.99992L0.195262 1.47132C-0.0650874 1.21097 -0.0650874 0.788864 0.195262 0.528514Z"
            clip-rule="evenodd"
            fill-rule="evenodd"
          ></path>
        </svg>
      </span>
    </button>
  {% endif %}
</div>

{% endif %}
</div>`;
