import {
  addParamsLocale,
  buildQueryParams,
  getLocalStorage,
  getQueryParamByKey,
  getThemeSettings,
  isMobile,
  isSearchPage,
  parseFilterTreeToParams,
  setLocalStorage,
  setPaginationSession,
} from '../utils';
import DOMPurify from 'dompurify';
import { collapseMobileBtn, getFilterSettings, renderFilterTree } from '../filter-tree';
import {
  FILTER_MOBILE_FULL_HEIGHT_SELECTOR,
  FILTER_MOBILE_WRAPPER_SELECTOR,
  FILTER_TREE_STATE_KEY,
} from '../constants/filter-tree.js';
import { PRE_ACTION, PRE_REQUEST_IDS } from '../constants/app.js';
import { transformProductPrice } from '../product-item/index.js';
import { getTemplateParsedById } from '../template/template-management';

export function initGetApi(context) {
  context.getFilterApi = async function (
    url,
    domId,
    addition = {
      responseType: 'json',
      modifyParams: null,
      additionParams: {},
    }
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('q');

    url = isSearchPage(context) || searchQuery ? context.app.searchUrl : url;

    // two class ref from main.liquid filter tree template please don't change two class.
    const classGlobalHide = 'boost-sd__g-hide';
    const idLoadingFilterIcon = 'boost-sd-loading-icon-filter';
    const loadingFilterTree = document.getElementById(idLoadingFilterIcon);

    // show filter loading icon => remove class hide
    if (loadingFilterTree) {
      loadingFilterTree.classList?.remove(classGlobalHide);
    }

    const { responseType = 'json', modifyParams, additionParams } = addition;
    let params = buildParams(context, additionParams, modifyParams);
    params = addParamsLocale(params);

    const queryParams = buildQueryParams(params);
    if (context.app.generalSettings.collection_id) {
      queryParams.set('collection_scope', context.app.generalSettings.collection_id);
      queryParams.set('tag', context.app.generalSettings?.collection_tags?.[0] || '');
    }

    if (context.app.b2b?.enabled) {
      queryParams.set(
        'company_location_id',
        `${context.app.b2b.current_company_id}_${context.app.b2b.current_location_id}`
      );
    }

    const key = queryParams.toString();
    const cache = context.cache || {};
    const htmlCache = cache[key] ? cache[key] : {};
    const newId = window.boostWidgetIntegration.generateUuid();
    context.latestRequestKey = key;
    let { html = null, filter = {} } = htmlCache;

    if (!html) {
      queryParams.set('t', Date.now());
      queryParams.set('sid', window.boostWidgetIntegration.getSessionId());
      const { sortingAvailableFirst, availableAfterFiltering, productAndVariantAvailable } =
        getFilterSettings(context);

      // build filter setting - product list - Sort available products first
      if (sortingAvailableFirst) {
        queryParams.set('sort_first', 'available');
      }

      // // build filter setting - product list - Out of stock display options
      if (!availableAfterFiltering && !productAndVariantAvailable) {
        // show out of stock products/variants : availableAfterFiltering:false, productAndVariantAvailable: false
        queryParams.set('product_available', false);
        queryParams.set('variant_available', false);
      } else if (!availableAfterFiltering && productAndVariantAvailable) {
        //hide out of stock products/variants: availableAfterFiltering:false, productAndVariantAvailable:true
        queryParams.set('product_available', true);
        queryParams.set('variant_available', true);
      } else {
        //only hide out of stock products/variants after filtering: availableAfterFiltering: true  productAndVariantAvailable:false
        queryParams.set('product_available', true);
        queryParams.set('variant_available', true);
      }

      let response = null;
      const widgetId = queryParams.get('widgetId');
      const template = getTemplateParsedById(context, widgetId);
      if (template) {
        queryParams.delete('widgetId');
        response = await fetch(`${url}?${queryParams}`, {
          method: 'GET',
        });
        response = await toJson(response);

        const queryObject = {};
        for (const [key, value] of queryParams.entries()) {
          queryObject[key] = value;
        }

        response.html = context.templateRender(template, {
          ...response,
          request: { ...queryObject, ...params },
        });
      } else {
        response = await fetch(`${url}?${queryParams}`, {
          method: 'GET',
        });
      }
      if (responseType === 'json') {
        const resJson = await toJson(response);

        html = DOMPurify.sanitize(await resJson.html);

        const requestId = await resJson.meta?.rid;
        const action = isSearchPage() ? 'search' : 'filter';
        saveRequestId(action, requestId);
        setLocalStorage(PRE_ACTION, action);

        context.state.filterTree = {
          ...resJson.filter,
          optionsMap: resJson.filter?.options?.reduce(
            (obj, val) => ({ ...obj, [val.filterOptionId]: val }),
            {}
          ),
        };
        const timestamp = new Date().getTime();
        context.cache[key] = {
          ...resJson,
          html,
          timestamp,
        };
        const maxCacheSize = 50;
        if (Object.keys(cache).length >= maxCacheSize) {
          const sortedKeys = Object.keys(cache).sort(
            (a, b) => cache[a].timestamp - cache[b].timestamp
          );
          delete cache[sortedKeys[0]];
        }
      } else {
        html = await response.text();
      }
    } else {
      context.state.filterTree = filter;
    }

    // replace default class none or display none in mobile from second call
    if (html) {
      const {
        filterLayout,
        filterTreeVerticalStyle,
        filterTreeMobileStyle,
        filterTreeHorizontalStyle,
      } = getFilterSettings(context);
      const [getFilterTreeDesktopOpening, setFilterTreeDesktopOpening] = context.useContextState(
        FILTER_TREE_STATE_KEY.DESKTOP_OPENING,
        false
      );

      // Update status filter tree toggle button icon
      const isMobileSize = isMobile(context?.app?.generalSettings?.isTabletPortraitMax);
      const filterTreeMobileWrapper = context.$(FILTER_MOBILE_WRAPPER_SELECTOR);
      if (
        isMobileSize &&
        filterTreeMobileStyle === 'style1' &&
        filterTreeMobileWrapper?.style?.display != 'none'
      ) {
        html = html.replace(
          'class="boost-sd__filter-tree-toggle-button"',
          'class="boost-sd__filter-tree-toggle-button boost-sd__filter-tree-toggle-button--active"'
        );
      }

      if (
        !isMobileSize &&
        filterTreeVerticalStyle != 'style-default' &&
        filterLayout === 'vertical' &&
        getFilterTreeDesktopOpening()
      ) {
        html = html.replace(
          'class="boost-sd__filter-tree-toggle-button boost-sd__filter-tree-toggle-button--expand"',
          'class="boost-sd__filter-tree-toggle-button boost-sd__filter-tree-toggle-button--expand boost-sd__filter-tree-toggle-button--active"'
        );
      }

      if (filterTreeVerticalStyle === 'style-off-canvas' && getFilterTreeDesktopOpening()) {
        // remove class display none
        html = html.replace(
          'class="boost-sd__filter-tree-vertical-sticky-overlay boost-sd__filter-tree-vertical--hidden"',
          'class="boost-sd__filter-tree-vertical-sticky-overlay"'
        );
      }

      if (filterTreeVerticalStyle === 'style-expand' && getFilterTreeDesktopOpening()) {
        // remove class display none
        html = html.replace(
          'class="boost-sd__filter-tree-vertical  boost-sd__filter-tree-vertical--hidden"',
          'class="boost-sd__filter-tree-vertical"'
        );
      }

      if (filterLayout === 'horizontal' && filterTreeHorizontalStyle === 'style-expand') {
        setFilterTreeDesktopOpening(false);
      }
    }

    if (context.latestRequestKey == key && domId) {
      const isAppendToProductListType =
        additionParams?.behavior === 'more' || additionParams?.behavior === 'previous';

      if (isAppendToProductListType) {
        // handle append/prepend to product list instead of replacing the whole page html
        handleProductListHTML(context, html, additionParams?.behavior);
      } else {
        handleReplaceHtml(context, () => {
          const dom = document.getElementById(domId);
          dom.innerHTML = DOMPurify.sanitize(html);
          if (responseType === 'json') context.state.latestFilterSearchRequest = newId;
        });

        setPaginationSession(getQueryParamByKey('page'));
      }
      const { paginationType = 'default' } =
        getThemeSettings(context)?.additionalElements?.pagination;

      if (paginationType === 'infinite_scroll') {
        // avoid infinite scroll firing multiple times when new page is still loading
        const [_, setIsLoading] = context.useContextState('pagination-infinite-loading ', false);
        setIsLoading(false);
      }

      // remove no scroll on body -> remove class
      document.body.classList.remove('boost-sd__g-no-scroll');

      // remove filter tree loading -> add class hide
      if (loadingFilterTree) {
        loadingFilterTree.classList.add(classGlobalHide);
      }

      return html;
    }
  };
}

async function toJson(res) {
  try {
    return await res.json();
  } catch (e) {
    return res;
  }
}

export const buildParams = (context, additionParams, modifyParams) => {
  const [getFilter] = context.useContextState('filter', []);
  const [getSort] = context.useContextState('sort', {});
  const [getPage] = context.useContextState('pagination ', {});
  const [getFilterToggleButton] = context.useContextState('filter-toggle-button', {});
  const filterParams = parseFilterTreeToParams(getFilter());

  let params = { ...context.defaultParams, ...filterParams };

  if (getSort && getSort().sort) {
    params['sort'] = getSort().sort;
  }
  if (getPage && getPage().page) {
    params['page'] = getPage().page;
  }
  params['isMobile'] = isMobile(context.app.generalSettings.isTabletPortraitMax || 575);
  params['isTabletPortraitMax'] = isMobile(context.app.generalSettings.isTabletPortraitMax);
  params['first_load'] = false;

  if (additionParams) {
    params = { ...params, ...additionParams };
  }
  if (getFilterToggleButton() && getFilterToggleButton().showFilterTree) {
    params['showFilterTree'] = true;
  }

  if (params.pf_tag) {
    params.tag = params.pf_tag;
    delete params.pf_tag;
  }

  // add param filter when filter has param
  if (Object.keys(filterParams).length !== 0) {
    params.filter = true;
  } else {
    delete params.filter;
  }

  return modifyParams ? modifyParams(params) : params;
};

export const getTemplateById = async (context, params) => {
  const url = context.app.templateUrl;

  const response = await fetch(
    `${url}?${buildQueryParams({ ...context.defaultParams, ...params })}`,
    {
      method: 'GET',
    }
  );
  return response.json();
};

// type: filter - search - recommend - suggest
export function saveRequestId(type = 'filter', request_id) {
  if (!request_id) return;

  const requestIds = getLocalStorage(PRE_REQUEST_IDS) || {};
  requestIds[type] = request_id;

  setLocalStorage(PRE_REQUEST_IDS, requestIds);
}

const handleReplaceHtml = (context, replaceFn) => {
  let mobile = context.$(FILTER_MOBILE_WRAPPER_SELECTOR);
  if (mobile?.style && mobile?.style?.display !== 'none') {
    const fullHeight = context.$(FILTER_MOBILE_FULL_HEIGHT_SELECTOR, mobile);
    if (fullHeight) {
      const fullHeightHtml = fullHeight.innerHTML;
      const fullHeightId = fullHeight.id;
      replaceFn();
      mobile = context.$(FILTER_MOBILE_WRAPPER_SELECTOR);
      collapseMobileBtn(context, { elementId: fullHeightId });
      if (context.$(`#${fullHeightId}`, mobile)) {
        context.$(`#${fullHeightId}`, mobile).innerHTML = fullHeightHtml;
      }
    } else {
      replaceFn();
    }

    if (context.$(FILTER_MOBILE_WRAPPER_SELECTOR)) {
      context.$(FILTER_MOBILE_WRAPPER_SELECTOR).style.display = 'unset';
    }
    renderFilterTree(context);
  } else {
    replaceFn();
  }
};

const handleProductListHTML = (context, html, behavior) => {
  const productList = context.document.querySelector('.boost-sd__product-list');
  html = html?.replace(/loading="lazy"/g, '');

  // remove outer div of new page html product list
  const outerDiv = document.createElement('div');
  outerDiv.innerHTML = html;
  const innerContent = outerDiv.firstChild;
  const modifiedHtml = innerContent?.innerHTML;

  if (behavior === 'previous') {
    const firstProductItem = productList.querySelector('.boost-sd__product-item');
    firstProductItem && firstProductItem.insertAdjacentHTML('beforebegin', modifiedHtml);
  } else if (behavior === 'more') {
    const lastProductItem = productList.querySelector('.boost-sd__product-item:last-child');
    lastProductItem && lastProductItem.insertAdjacentHTML('afterend', modifiedHtml);
  }

  transformProductPrice(context);
};
