//import { getProductItemData } from '../utils';

const IntegrationProductLabel = (type) => {
  /**
   * Recall callback function after render product list
   */
  const callbackFunc = () => {
    try {
      switch (type) {
        case 'deco':
          if (typeof window.SECOMAPP?.pl?.labelCollections === 'function') {
            window.SECOMAPP.pl.labelCollections(true);
          }

          break;

        case 'flair':
          if (typeof window.FlairApp?.refreshProductBadges === 'function') {
            window.FlairApp.refreshProductBadges();
          }

          break;
        default:
          return null;
      }
    } catch (error) {
      console.log('Error when try to callbackFunc() from 3rd app');
      console.log(error.stack);
    }
  };

  callbackFunc();
};

export default IntegrationProductLabel;
