export const loadJudgeMeScript = () => {
  !(function (e) {
    (window.jdgm = window.jdgm || {}),
      (jdgm.CDN_HOST = 'https://cdn.judge.me/'),
      (jdgm.docReady = function (d) {
        (e.attachEvent ? 'complete' === e.readyState : 'loading' !== e.readyState)
          ? setTimeout(d, 0)
          : e.addEventListener('DOMContentLoaded', d);
      }),
      (jdgm.loadCSS = function (d, t, o, a) {
        (!o && jdgm.loadCSS.requestedUrls.indexOf(d) >= 0) ||
          (jdgm.loadCSS.requestedUrls.push(d),
          ((a = e.createElement('link')).rel = 'stylesheet'),
          (a.class = 'jdgm-stylesheet'),
          (a.media = 'nope!'),
          (a.href = d),
          (a.onload = function () {
            (this.media = 'all'), t && setTimeout(t);
          }),
          e.body.appendChild(a));
      }),
      (jdgm.loadCSS.requestedUrls = []),
      (jdgm.loadJS = function (e, d) {
        var t = new XMLHttpRequest();
        (t.onreadystatechange = function () {
          4 === t.readyState && (Function(t.response)(), d && d(t.response));
        }),
          t.open('GET', e),
          t.send();
      }),
      jdgm.docReady(function () {
        (window.jdgmLoadCSS ||
          e.querySelectorAll('.jdgm-widget, .jdgm-all-reviews-page').length > 0) &&
          typeof jdgmSettings !== 'undefined' &&
          (jdgmSettings.widget_load_with_code_splitting
            ? parseFloat(jdgmSettings.widget_version) >= 3
              ? jdgm.loadCSS(jdgm.CDN_HOST + 'widget_v3/base.css')
              : jdgm.loadCSS(jdgm.CDN_HOST + 'widget/base.css')
            : jdgm.loadCSS(jdgm.CDN_HOST + 'shopify_v2.css'),
          jdgm.loadJS(jdgm.CDN_HOST + 'loader.js'));
      });
  })(document);
};
