import { loadJudgeMeScript } from './judge.me';

const IntegrationProductReview = (type) => {
  let loadedScriptJudgeMe = false;

  /**
   * Declare callback function
   */
  const callbackFunc = () => {
    try {
      switch (type) {
        case 'judgeme':
          if (
            !loadedScriptJudgeMe &&
            document.querySelectorAll('.boost-sd__integration-product-rating').length > 0
          ) {
            loadedScriptJudgeMe = true;
            return loadJudgeMeScript();
          }

        case 'aliReview':
          if (typeof window.aliReviewsAddRatingCollection === 'function')
            return window.aliReviewsAddRatingCollection();

        case 'LAIAliExpress':
          if (
            window.SMARTIFYAPPS?.rv?.installed &&
            typeof window.SMARTIFYAPPS?.rv?.scmReviewsRate?.actionCreateReviews === 'function'
          )
            return window.SMARTIFYAPPS?.rv?.scmReviewsRate?.actionCreateReviews();

        case 'prs':
          if (!window.SPR) return;

          if (typeof window.SPR?.initDomEls === 'function') {
            window.SPR?.initDomEls();
          }

          if (typeof window.SPR?.loadBadges === 'function') {
            window.SPR?.loadBadges();
          }

          break;

        case 'okendo':
          const okendoWidgetPlusEnabled =
            document && !!document.getElementById('okendo-reviews-script');
          let okendoSelector;
          if (okendoWidgetPlusEnabled) {
            okendoSelector = document.getElementsByClassName('okendo-StarRatingSnippet');
          } else {
            okendoSelector = document.getElementsByClassName('okendo-ProductListingSnippet');
          }
          //Set to only 1 metafields show in front store
          for (let i = 0; i < okendoSelector.length; i++) {
            okendoSelector[i].style.display = '';
          }

          break;

        case 'rivyo':
          if (typeof window.th_product_rivyo !== 'undefined') {
            const getDataReview = (shopBadgeUrls) => {
              return new Promise((resolve, reject) => {
                const requestInstance = new XMLHttpRequest();
                requestInstance.open(
                  'POST',
                  '//thimatic-apps.com/product_review/get_review_rating_update.php'
                );
                requestInstance.setRequestHeader(
                  'Content-Type',
                  'application/x-www-form-urlencoded;charset=UTF-8'
                );
                requestInstance.onload = function () {
                  try {
                    const dataResponse = JSON.parse(requestInstance.responseText);
                    if (requestInstance.readyState > 3) {
                      if (200 === requestInstance.status) {
                        resolve(dataResponse);
                      } else {
                        reject(null);
                      }
                    }
                  } catch (error) {
                    reject(null);
                  }
                };
                requestInstance.send(shopBadgeUrls);
              });
            };
            const shopUrl = window.boostWidgetIntegration?.app?.staging?.shop?.domain || '';
            const productReviewBadges = document.querySelectorAll(
              '.wc_product_review_badge_boostcommerce'
            );
            let shopBadgeUrls = `shop=${shopUrl}`;
            let product_count = 0;
            productReviewBadges.forEach(async (element, index) => {
              let product_id = element?.dataset?.product_id;

              if (
                element.querySelectorAll('.wc_review_badge_inner').length == 0 &&
                element.querySelectorAll('.wc_review_badge_inner_empty').length == 0 &&
                product_id != ''
              ) {
                shopBadgeUrls += '&product_ids[]=' + product_id;
                product_count = product_count + 1;
              }
              if (index === productReviewBadges.length - 1 && product_count != 0 && shopUrl != '') {
                const response = await getDataReview(shopBadgeUrls);
                if (response) {
                  productReviewBadges.forEach((element) => {
                    if (element) element.innerHtml = response?.empty?.html || '';
                  });

                  for (const property in response) {
                    const productReviewBadge = document.querySelectorAll(
                      `.wc_product_review_badge_boostcommerce[data-product_id="${property}"]`
                    );

                    // eslint-disable-next-line no-loop-func
                    productReviewBadge?.forEach((element) => {
                      if (element) {
                        element.innerHTML = response[property]?.html;
                      }
                    });
                  }
                }
              }
            });
          }

          break;

        case 'stamped':
          if (
            typeof window.StampedFn?.loadBadges === 'function' ||
            document.getElementById('stamped-script-widget')
          )
            return window.StampedFn.loadBadges();

        case 'yotpo':
          // version 2
          if (window?.yotpo && typeof window?.yotpo.initWidgets === 'function') {
            yotpo.initWidgets();
          }

          // version 3
          if (
            window?.yotpoWidgetsContainer &&
            typeof window?.yotpoWidgetsContainer.initWidgets === 'function'
          )
            yotpoWidgetsContainer.initWidgets();

          break;

        default:
          return null;
      }
    } catch (error) {
      console.log('Error when try to callbackFunc() from 3rd app');
      console.log(error.stack);
    }
  };

  callbackFunc();
};

export default IntegrationProductReview;
