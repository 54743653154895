import {
  scrollToTop,
  isEqual,
  removeQueryParamHistory,
  setQueryParamHistory,
  isMobile,
  replaceDotCommaZero,
} from '../utils';
import { resetFilterRequest } from '../api/filter';
import { getFilterSettings } from './index.js';

export function renderRangeSlider(context, dom, option) {
  const { $ } = context;
  let { sliderStep, sliderRange } = option;
  let { min, max } = option.values;

  if (min === max) {
    return;
  }

  const [getRangeFilter, setRangeFilter] = context.useContextState('rangeFilter', {});
  const [getFilter, setFilter] = context.useContextState('filter', []);

  const filterState = getFilter();
  const rangeFilter = getRangeFilter();
  const range = max - min;
  if (isNaN(sliderStep)) {
    sliderStep = 1;
  }

  if (isNaN(sliderRange)) {
    sliderRange = 1;
  }

  sliderStep = Math.min(sliderStep, (max - min) / sliderRange);

  const { filterLayout, requestInstantly } = getFilterSettings(context);
  const isFilterVertical =
    filterLayout !== 'horizontal' || isMobile(context.app.generalSettings.isTabletPortraitMax);

  const horizontalHasApplyButton =
    filterLayout === 'horizontal' &&
    !requestInstantly &&
    !isMobile(context.app.generalSettings.isTabletPortraitMax);

  const slider = $('.boost-sd__filter-option-range-slider-slider', dom);

  if (!slider) return;
  // boost-sd__filter-option-range-slider-unit

  const sliderBase = $('.noUi-base', dom);
  const minHandle = $('.noUi-handle-lower', dom);
  const maxHandle = $('.noUi-handle-upper', dom);

  // inputLower and inputUpper can be null when settings hide input minmax box
  let inputLower = $('.boost-sd__filter-option-range-slider-input[aria-label="Min value"]', dom);
  let inputUpper = $('.boost-sd__filter-option-range-slider-input[aria-label="Max value"]', dom);

  const sliderOption = initSliderOption(option, filterState, inputLower, inputUpper);

  function changeStateSlider({ key = 'lower', value }) {
    sliderOption.action.value[key] = value;

    const percent = (value - min) / range;
    const newTran = 100 - percent * 100;

    if (key === 'lower') {
      inputLower.value = value;
      minHandle.parentNode.style.transform = `translate(-${newTran}%, 0px)`;
    } else if (key === 'upper') {
      inputUpper.value = value;
      maxHandle.parentNode.style.transform = `translate(-${newTran}%, 0px)`;
    }

    context.updatedParams && delete context.updatedParams[sliderOption.action.key];
    isFilterVertical && scrollToTop({ isFilter: true });
    sliderOption.action.display[key] = newTran;
    rangeFilter[option.filterOptionId] = sliderOption;
    sliderOption.status = 'selected';

    setRangeFilter(rangeFilter);
    const newFilterTree = addRangeValueToFilterTree(
      context,
      getFilter(),
      option,
      { key: option.filterOptionId, value: sliderOption.action.value },
      horizontalHasApplyButton
    );

    if (newFilterTree) {
      setFilter(newFilterTree);
      isFilterVertical && resetFilterRequest(context);
    }
  }

  if (inputLower) {
    inputLower.addEventListener('change', function (event) {
      let value = Number(event.target.value);

      // invalid value the assign old value or max min
      if (value > sliderOption.action.value.upper || value < min || value > max) {
        value = sliderOption.action.value.lower || min;
        inputLower.value = value;
      }

      changeStateSlider({ key: 'lower', value });
    });

    inputLower.value = sliderOption.action.value.lower || min;
  }

  if (inputUpper) {
    inputUpper.addEventListener('change', function (event) {
      let value = Number(event.target.value);

      // invalid value the assign old value or max value
      if (value < sliderOption.action.value.lower || value < min || value > max) {
        value = sliderOption.action.value.upper || max;
        inputUpper.value = value;
      }

      changeStateSlider({ key: 'upper', value });
    });

    inputUpper.value = sliderOption.action.value.upper || max;
  }

  minHandle.parentNode.style.transform = `translate(-${sliderOption.action.display.lower}%, 0px)`;
  maxHandle.parentNode.style.transform = `translate(-${sliderOption.action.display.upper}%, 0px)`;

  let isDragging = false;
  let activeHandle = null;
  let handler = null;

  const mouseDownListener = (event) => {
    if (event.target === minHandle) {
      isDragging = true;
      activeHandle = event.target;
      handler = 'minHandle';
    }
    if (event.target.parentNode === minHandle) {
      isDragging = true;
      activeHandle = event.target.parentNode;
      handler = 'minHandle';
    }

    if (event.target === maxHandle) {
      isDragging = true;
      activeHandle = event.target;
      handler = 'maxHandle';
    }
    if (event.target.parentNode === maxHandle) {
      isDragging = true;
      activeHandle = event.target.parentNode;
      handler = 'maxHandle';
    }
  };
  const mouseMoveListener = (event) => {
    if (isDragging) {
      // event.clientX for mousemove event and event.pageX for touchmove event
      let clientX = event.clientX || event.pageX;
      if (!clientX && event.touches) {
        clientX = event.touches[0].clientX;
      }
      const startX = sliderBase.getBoundingClientRect().left;
      const endX = startX + sliderBase.offsetWidth;
      // 2 is magic number - make sure clientX -> over endX and over startX
      if (clientX < startX - 2 || clientX > endX + 2) return;

      const percent = (clientX - startX) / sliderBase.offsetWidth;
      let newValue = percent * range + min;
      newValue = replaceDotCommaZero(parseFloat(newValue).toFixed(2));
      newValue = Math.min(Math.max(newValue, min), max);

      // lower -100 >> 0
      // upper 0 >> -100
      let newTran = 100;
      if (handler === 'minHandle') {
        sliderOption.action.value.lower = newValue;
        if (inputLower) {
          inputLower.value = newValue;
        }

        newTran = 100 - percent * 100;
        sliderOption.action.display.lower = newTran;
      }

      if (handler === 'maxHandle') {
        sliderOption.action.value.upper = newValue;
        if (inputUpper) {
          inputUpper.value = newValue;
        }

        newTran = 100 - percent * 100;
        sliderOption.action.display.upper = newTran;
      }

      sliderOption.status = 'selected';
      activeHandle.parentNode.style.transform = `translate(-${newTran}%, 0px)`;
    }
  };

  const mouseUpListener = (event) => {
    if (isDragging) {
      isDragging = false;
      activeHandle = null;
      let newFilterTree = null;
      const value = sliderOption.action.value;
      if (value.lower > value.upper) return;

      if (
        sliderOption.status === 'selected' &&
        (!isEqual(rangeFilter[option.filterOptionId], sliderOption) || !isFilterVertical) &&
        value.lower !== null &&
        value.upper !== null
      ) {
        context.updatedParams && delete context.updatedParams[sliderOption.action.key];
        isFilterVertical && scrollToTop({ isFilter: true });
        rangeFilter[option.filterOptionId] = sliderOption;
        setRangeFilter(rangeFilter);
        newFilterTree = addRangeValueToFilterTree(
          context,
          getFilter(),
          option,
          { key: option.filterOptionId, value: sliderOption.action.value },
          horizontalHasApplyButton
        );
      }
      if (value.lower === min && value.upper === max) {
        sliderOption.status = 'unselected';
        isFilterVertical && scrollToTop({ isFilter: true });
        rangeFilter[option.filterOptionId] = sliderOption;
        setRangeFilter(rangeFilter);

        newFilterTree = removeRangeValueFromFilterTree(getFilter(), {
          key: option.filterOptionId,
          value,
        });
      }

      if (newFilterTree) {
        setFilter(newFilterTree);
        isFilterVertical && resetFilterRequest(context);
      }
    }
  };

  slider.addEventListener('mousedown', mouseDownListener);
  slider.addEventListener('touchstart', mouseDownListener);
  slider.addEventListener('mousemove', mouseMoveListener);
  slider.addEventListener('touchmove', mouseMoveListener);
  document.addEventListener('mouseup', mouseUpListener);
  document.addEventListener('touchend', mouseUpListener);
}

const addRangeValueToFilterTree = (
  context,
  filterTree,
  option,
  action,
  horizontalHasApplyButton
) => {
  const { key, value } = action;
  const stringValue = toStringValue(value);
  const rangeFilter = filterTree?.filter((f) => f?.data?.key === key);
  if (rangeFilter?.length > 0) {
    rangeFilter.forEach((f) => {
      f.data.value = value;
      f.metaData = { ...(f.metaData || {}), value: stringValue };
    });
  } else {
    const filter = generateFilterDataForRange(key, value, option);
    filterTree.push(filter);
  }
  setQueryParamHistory(key, stringValue);

  if (horizontalHasApplyButton) {
    const [getItemSelecting, setItemSelecting] = context.useContextState(
      'filterOptionItemSelecting',
      {}
    );

    const _getItemSelecting = getItemSelecting();

    if (!_getItemSelecting[`${option.filterOptionId}`]) {
      _getItemSelecting[`${option.filterOptionId}`] = true;
    }

    setItemSelecting(_getItemSelecting);
  }
  return filterTree;
};

const removeRangeValueFromFilterTree = (filterTree, option) => {
  const { key, value } = option.action;
  const encodeValue = toStringValue(value);
  removeQueryParamHistory(key, encodeValue);
  return filterTree.filter((f) => f.data.key !== key);
};

export const generateFilterDataForRange = (key, value, option) => {
  return {
    data: {
      key,
      value: toRangeValue(value),
      label: option.label,
      moneyFormatValue: option?.moneyFormatValue || '',
    },
    metaData: { key, value: toStringValue(value), type: option.displayType || 'range' },
    type: option.displayType || 'range',
  };
};

const toStringValue = (value) => {
  if (typeof value === 'string') {
    return value;
  }

  return `${value.lower}:${value.upper}`;
};
const toRangeValue = (value) => {
  if (typeof value === 'string') {
    const spit = value.split(':');
    return { lower: spit[0], upper: spit[1] };
  }

  return value;
};

const initSliderOption = (option, filterTree, inputLower = {}, inputUpper = {}) => {
  const { min, max } = option.values;
  const sliderOption = {
    action: {
      key: option.filterOptionId,
      value: { lower: min, upper: max },
      range: {
        lower: min,
        upper: max,
      },
      display: { lower: -100, upper: 0 },
      label: option.label,
      position: option.position,
      moneyFormatValue: option.moneyFormatValue,
      actionId: 'slider',
    },
    type: 'range',
    status: 'unselected',
  };

  const rangeFilter = filterTree?.filter((f) => f?.data?.key === option.filterOptionId);
  if (rangeFilter?.length > 0) {
    const valueSelected = rangeFilter[0]?.data?.value;

    if (valueSelected?.lower < min) {
      sliderOption.action.value.lower = min;
      if (inputLower) {
        inputLower.value = min;
      }
    }

    if (valueSelected?.upper > max) {
      sliderOption.action.value.upper = max;
      if (inputUpper) {
        inputUpper.value = max;
      }
    }

    sliderOption.action.value = toRangeValue(rangeFilter[0].data.value);
    const range = max - min;

    const newTranLower = 100 - ((sliderOption.action.value.lower - min) / range) * 100;
    const newTranUpper = 100 - ((sliderOption.action.value.upper - min) / range) * 100;

    sliderOption.action.display = {
      lower: Math.min(newTranLower, 100),
      upper: Math.max(newTranUpper, 0),
    };
  }

  return sliderOption;
};
