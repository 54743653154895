import { addParamsLocale, buildQueryParams } from '../utils/index.js';

export const getProductDetail = async (context, params) => {
  params = addParamsLocale(params);
  if (context.app.b2b?.enabled) {
    params.company_location_id = `${context.app.b2b.current_company_id}_${context.app.b2b.current_location_id}`;
  }

  const response = await fetch(`${context.app.productUrl}?${buildQueryParams(params)}`, {
    method: 'GET',
  });
  //Generate newId for customize
  const newId = window.boostWidgetIntegration.generateUuid();
  context.state.latestQuickViewRequest = newId;
  return response.text();
};
