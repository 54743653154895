export const LocalStorageKey = 'boostSDRecentlyViewedProduct';
const LIMIT = 40;

const validateProductLimit = () => {
  const productsRaw = window.localStorage.getItem(LocalStorageKey);

  if (!productsRaw) {
    window.localStorage.setItem(LocalStorageKey, JSON.stringify([]));
    return;
  }

  let products = JSON.parse(productsRaw);

  if (products.length > LIMIT) {
    products = products.slice(0, LIMIT);
    window.localStorage.setItem(LocalStorageKey, JSON.stringify(products));
  }
};

export const getRecentlyViewedProducts = () => {
  try {
    validateProductLimit();

    const productsRaw = window.localStorage.getItem(LocalStorageKey);
    if (!productsRaw) return [];

    return JSON.parse(productsRaw);
  } catch (error) {
    return [];
  }
};

export const addRecentlyViewedProduct = (productId) => {
  const products = getRecentlyViewedProducts();

  if (products.includes(productId)) return;

  products.unshift(productId);

  window.localStorage.setItem(LocalStorageKey, JSON.stringify(products));

  validateProductLimit();
};
