import { debounce } from '../utils';

const requestInCollectionSearch = (context, urlParams) => {
  const [_, setPage] = context.useContextState('pagination ', {});
  setPage({ page: 1 });
  urlParams.delete('page');

  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
  context.getFilterApi(context.app.filterUrl, context.id, {
    additionParams: { inCollectionSearchFocusing: true },
  });
};
export const handleInCollectionSearch = (context) => {
  context.render(() => {
    const inCollectionSearch = document.querySelector('.boost-sd__in-collection-search-input');

    if (inCollectionSearch) {
      const urlParams = new URLSearchParams(window.location.search);
      const q = urlParams.get('q');
      const dataInputFocusing = inCollectionSearch.hasAttribute('data-input-focusing');
      const clearBtn = document.querySelector('.boost-sd__in-collection-search-clear-btn');

      if (dataInputFocusing) {
        const end = inCollectionSearch.value.length;
        inCollectionSearch.setSelectionRange(end, end);
        inCollectionSearch.focus();
      }

      const delay = context.app?.generalSettings?.inCollectionSearchDelay || 500;

      inCollectionSearch?.addEventListener(
        'keyup',
        debounce((event) => {
          // set 'q' param in the URL
          if (event.target.value === '') {
            urlParams.delete('q');
          } else {
            urlParams.set('q', event.target.value);
          }

          requestInCollectionSearch(context, urlParams);
        }, delay)
      );
      clearBtn?.addEventListener('click', () => {
        urlParams.delete('q');
        requestInCollectionSearch(context, urlParams);
      });
    }
  }, ['filterTree']);
};
