import { callFilterRequest } from '../api/filter.js';
import { isMobile } from '../utils';

export const handleViewAsClick = async (context, target) => {
  if (target.classList.contains('boost-sd__view-as-icon')) {
    context.defaultParams.viewAs = target.getAttribute('data-value');
  } else {
    context.defaultParams.viewAs = target
      .closest('.boost-sd__view-as-icon')
      .getAttribute('data-value');
  }

  const isMobileSize = isMobile(context.app.generalSettings.breakpointmobile || 575);
  context.defaultParams.device = isMobileSize ? 'mobile' : '';

  return await callFilterRequest(context);
};
