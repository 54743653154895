export const filterOptionRatingTemplate = `
<li
  data-value='{{ value.key }}'
  data-action='{{ dataAction }}'
  class='boost-sd__filter-option-item boost-sd__filter-option-rating-item'
>
  <button
    id='{{ actionId }}'
    data-action='{{ dataAction }}'
    role='checkbox'
    aria-checked='false'
    aria-label='{{ value.key }} Stars. Number of products: {{value.doc_count}}'
    class='{{ className }}'
  >
    <span
      data-action='{{ dataAction }}'
      role='presentation'
      class='boost-sd__filter-option-rating-item-presentation'
    >
      {% for i in (0..4) %}
        {% if i < value.from %}
          <i
            data-action='{{ dataAction }}'
            class='boost-sd__filter-option-rating-item-icon-star boost-sd__filter-option-rating-item-icon-star--active'
            style='color: {{ starColor }} ;'
          ></i>
        {% else %}
          <i
            data-action='{{ dataAction }}'
            class='boost-sd__filter-option-rating-item-icon-star'
            style='color: {{ starColor }} ;'
          ></i>
        {% endif %}
      {% endfor %}

      {% if showExactRating == false %}
        <span data-action='{{ dataAction }}'>{{ "ratingUp" | translate: translations, "& Up" }}</span>
      {% endif %}
    </span>
    <span
      data-action='{{ dataAction }}'
      aria-label='Number of products: ({{ value.doc_count }})'
      class='boost-sd__filter-option-item-amount'
      >({{ value.doc_count }})</span
    >
  </button>
</li>`;
