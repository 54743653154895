import IntegrationProductReview from './product-review';
import IntegrationMultipleCurrency from './multiple-currency';
import IntegrationMultipleLanguage from './multiple-language';
import IntegrationOther from './other';
import IntegrationProductLabel from './product-label';
import IntegrationWishlist from './wishlist';

export function appIntegration() {
  //const integration = window.boostWidgetIntegration?.integration;
  const env = window.boostWidgetIntegration?.env;
  const integration = window.boostWidgetIntegration?.app?.[env]?.integration;
  if (integration) {
    IntegrationProductReview(integration.reviews);
    IntegrationWishlist(integration.wishlists);
    IntegrationMultipleCurrency(integration.currencies);
    IntegrationMultipleLanguage(integration.languages);
    IntegrationProductLabel(integration.label);
    //IntegrationOther(integration.others);
  }
}
