import { callFilterRequest, resetFilterRequest } from '../api/filter';
import {
  NEXT_PAGE,
  PREV_PAGE,
  SELECTED_PRODUCT_ID,
  SELECTED_PRODUCT_PAGE,
} from '../constants/pagination';
import {
  calcPageAction,
  deleteQueryParamHistory,
  getSessionStorage,
  scrollToTop,
  setQueryParamHistory,
  setSessionStorage,
  isMobile,
  getThemeSettings,
  getQueryParamByKey,
  translateWithComponent,
  debounce,
} from '../utils';

let observer;

export const pagination = (context, target) => {
  const [getPage, setPage] = context.useContextState('pagination ', {
    page: 1,
  });

  let behavior = 'refresh';

  let newPage = getPage().page || 1;

  switch (true) {
    // page link
    case target.classList.contains('boost-sd__pagination-number'):
      const pageValue = target.textContent.trim();
      newPage = calcPageAction(newPage, pageValue);
      break;
    case target.classList.contains('boost-sd__pagination-button--prev'):
      newPage = calcPageAction(newPage, 'prev');
      break;
    case target.classList.contains('boost-sd__pagination-button--next'):
      newPage = calcPageAction(newPage, 'next');
      break;

    // load more/infinite scroll
    case target.closest('.boost-sd__pagination-button--load-previous') !== null:
    case target.closest('.boost-sd__pagination-infinite-scroll-container-button') !== null:
      newPage = calcPageAction(getSessionStorage(PREV_PAGE), 'prev');
      behavior = 'previous';
      break;
    case target.closest('.boost-sd__pagination-button--load-more') !== null:
      newPage = calcPageAction(getSessionStorage(NEXT_PAGE), 'next');
      behavior = 'more';
      break;
  }

  processPaginatingAction(context, newPage, behavior);
};

const processPaginatingAction = (context, newPage, behavior) => {
  const { paginationTypeAdvanced = true } = context?.app?.generalSettings;

  const [getPage, setPage] = context.useContextState('pagination ', {
    page: 1,
  });

  const { paginationType = 'default' } = getThemeSettings(context)?.additionalElements?.pagination;

  const widgetId =
    paginationType === 'default' ? context.widgetId : context.app.templateMetadata.productList;

  behavior = paginationType !== 'default' ? behavior : 'refresh';

  if (behavior === 'more') {
    setSessionStorage(NEXT_PAGE, newPage);
  }
  if (behavior === 'previous') {
    setSessionStorage(PREV_PAGE, newPage);
  }

  if (newPage !== Number(getPage().page)) {
    setPage({ page: newPage });
    if (newPage === 1) {
      deleteQueryParamHistory('page');
    } else {
      paginationTypeAdvanced && setQueryParamHistory('page', newPage);
    }

    callFilterRequest(context, {
      additionParams: {
        widgetId,
        behavior,
      },
    });

    if (paginationType === 'default') {
      scrollToTop();
    } else {
      handlePaginationLoadMoreButtons(context, newPage);
    }
  }
};

export const handlePaginationInfiniteScroll = (context) => {
  const [getIsLoading, setIsLoading] = context.useContextState(
    'pagination-infinite-loading ',
    false
  );

  const target = context.document.querySelector(
    '.boost-sd__pagination-infinite-scroll-container-target'
  );

  const paginationLoadPreviousButton = context.document.querySelector(
    '.boost-sd__pagination-load-more-container-item[data-position="top"], .boost-sd__pagination-infinite-scroll-container-button'
  );

  const paginationLoadMoreButton = context.document.querySelector(
    '.boost-sd__pagination-load-more-container-item[data-position="bottom"]'
  );

  let totalPage = 0;

  const productList = context.document.querySelector('.boost-sd__product-list');

  if (productList) {
    totalPage = Number(productList.getAttribute('data-total-page'));
  }

  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (getIsLoading()) return;

        setIsLoading(true);

        if (getSessionStorage(NEXT_PAGE) < totalPage) {
          debounce(processPaginatingAction(context, getSessionStorage(NEXT_PAGE) + 1, 'more'), 350);
        }
      }
    });
  };

  if (target) {
    let options = {
      rootMargin: '0px',
      threshold: 0.75,
    };

    observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(target);
  }
};

const handlePaginationLoadMoreButtons = (context, currentPage) => {
  const paginationLoadPreviousButton = context.document.querySelector(
    '.boost-sd__pagination-load-more-container-item[data-position="top"], .boost-sd__pagination-infinite-scroll-container-button'
  );

  const productCountPrev = context.document.querySelector(
    '.boost-sd__product-count[data-position="top"]'
  );

  const paginationLoadMoreButton = context.document.querySelector(
    '.boost-sd__pagination-load-more-container-item[data-position="bottom"]'
  );

  const productCountNext = context.document.querySelector(
    '.boost-sd__product-count[data-position="bottom"]'
  );

  const limit = context?.defaultParams?.limit || 24;

  let totalPage = 0;
  let totalProduct = 0;
  const productList = context.document.querySelector('.boost-sd__product-list');

  if (productList) {
    totalPage = Number(productList.getAttribute('data-total-page'));
    totalProduct = Number(productList.getAttribute('data-total-product'));
  }

  const toProduct = Math.min(limit * getSessionStorage(NEXT_PAGE), totalProduct);
  const fromProduct = (getSessionStorage(PREV_PAGE) - 1) * limit + 1;

  if (currentPage <= 1) {
    if (paginationLoadPreviousButton) paginationLoadPreviousButton.style.display = 'none';
    if (productCountPrev) productCountPrev.style.display = 'none';
  }
  if (currentPage === totalPage) {
    if (paginationLoadMoreButton) paginationLoadMoreButton.style.display = 'none';
    if (productCountNext) productCountNext.style.display = 'none';
  }

  const productCountTexts = context.document.querySelectorAll(
    '.boost-sd__product-count-pagination'
  );

  const translationKey =
    totalProduct > 1
      ? 'productCount.textDescriptionPaginationPlural'
      : 'productCount.textDescriptionPagination';
  const translationFallback =
    totalProduct > 1
      ? 'Showing {{from}} - {{to}} of {{total}} products'
      : 'Showing {{from}} - {{to}} of {{total}} products';

  const productCountTextContent = translateWithComponent(
    context.translate(translationKey, translationFallback),
    { from: fromProduct, to: toProduct, total: totalProduct }
  );

  setTimeout(() => {
    productCountTexts?.forEach((productCountText) => {
      if (productCountText) productCountText.textContent = productCountTextContent;
    });
  }, 500);
};

export const scrollRestoration = (context) => {
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
  }

  const listener = (event) => {
    // Webpage is loading from cache
    if (event.persisted) {
      scrollRestoration(context);
    }
  };

  const productList = context.$('.boost-sd__product-list');

  if (productList) {
    const productId = getSessionStorage(SELECTED_PRODUCT_ID);

    if (productId) {
      const product = document.querySelector(
        `.boost-sd__product-item[data-product-id="${productId}"]`
      );

      // Has product id and not in recommendation
      if (product && !product.closest('.boost-sd__recommendation')) {
        window.scroll({
          top: product.getBoundingClientRect().top,
          behavior: 'smooth',
        });
      }
    }

    setSessionStorage(SELECTED_PRODUCT_ID, null);
    setSessionStorage(SELECTED_PRODUCT_PAGE, null);
  }

  // Handle IOS Scroll Restoration when page is loaded from cache
  window.addEventListener('pageshow', listener);

  return () => {
    window.removeEventListener('pageshow', listener);
  };
};

export const handleProductListResponsive = (context) => {
  const productList = context.$('.boost-sd__product-list');
  const productListListColumn = context.$('.boost-sd__product-list-list-col');

  if (!productList) {
    return;
  }

  window.addEventListener('resize', function () {
    const viewAsIconGridActive = context.$('.boost-sd__view-as-icon--active');
    const viewAsIconGrid = context.$('.boost-sd__view-as-icon--grid');

    const { productsPerRowOnDesktop, productsPerRowOnMobile } = context.app.templateSettings
      ?.themeSettings?.productList || {
      productsPerRowOnMobile: 2,
      productsPerRowOnDesktop: 3,
    };
    const { listType } = context.app.templateSettings?.themeSettings?.additionalElements?.toolbar
      ?.elements?.viewAs || { listType: 'grid/list' };
    const isMobileSize = isMobile(context.app.generalSettings.breakpointmobile || 575);
    const gridValue = viewAsIconGridActive?.getAttribute('data-value');

    const productListGridDesktop = 'boost-sd__product-list-' + gridValue + '-col';
    const productListGridMobile = 'boost-sd__product-list-grid--' + productsPerRowOnMobile + '-col';

    if (isMobileSize && !productListListColumn) {
      productList.classList.remove(productListGridDesktop);
      productList.classList.add(productListGridMobile);
      viewAsIconGrid?.setAttribute('data-value', 'grid--' + productsPerRowOnMobile);
    }

    if (!isMobileSize && !productListListColumn) {
      productList.classList.remove(productListGridMobile);
      productList.classList.add(productListGridDesktop);
      if (listType === 'grid/list') {
        viewAsIconGrid?.setAttribute('data-value', 'grid--' + productsPerRowOnDesktop);
      } else {
        viewAsIconGrid?.setAttribute('data-value', gridValue);
      }
    }
  });
};

export const handleExceededPage = async (context) => {
  // reset filter request if page exceeded total page
  const productList = context.document.querySelector('.boost-sd__product-list');

  if (productList) {
    const totalPage = Number(productList.getAttribute('data-total-page'));
    const pageParam = Number(context.defaultParams.page) || 1;

    if (pageParam > totalPage) {
      return await resetFilterRequest(context);
    }
  }
};
