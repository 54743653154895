export const cartTemplate = `<div class="boost-sd__modal" id="boost-sd__modal-card" aria-modal="true" role="dialog" aria-label="modal">
<div class="boost-sd__modal-backdrop"></div>
<div class="boost-sd__modal-container">
  <div tabindex="-1" class="boost-sd__modal-wrapper">
    <div class="boost-sd__cart {% if cartStyle == 'side' %}boost-sd__cart--side{% endif %}">
      <div class="boost-sd__cart-header {% if cartStyle == 'popup' %}boost-sd__cart-header--bigger-space{% endif %}">
        <div class="boost-sd__cart-items-count">
          {% if item_count == 1 %}
          {{ "cart.atcMiniCartShopingCartLabel" | translate: translations, "Your cart"  }} ({{ item_count }} {{ "cart.atcMiniCartCountItemLabel" | translate: translations, "item"  }})
          {% else %}
          {{ "cart.atcMiniCartShopingCartLabel" | translate: translations, "Your cart"  }} ({{ item_count }} {{ "cart.atcMiniCartCountItemLabelPlural" | translate: translations, "items" }})
          {% endif %}
        </div>
      </div>
      <div class="boost-sd__cart-body {% if cartStyle == 'popup' %}boost-sd__cart-body--bigger-space{% endif %}">
        {% for item in items %}
        <div class="boost-sd__cart-item" data-product-id="{{ item.id }}">
          <div class="boost-sd__cart-item-image">
            <a class="boost-sd__product-link" href="/products/{{ item.handle }}">
              <div class="boost-sd__product-image-wrapper boost-sd__product-image-wrapper--aspect-ratio-3-4">
                <div class="boost-sd__product-image">
                  {% if item.image %}
                  <img
                    id="product-image-{{ item.id }}"
                    class="boost-sd__product-image-img boost-sd__product-image-img--main"
                    src="{{ item.image }}"
                    srcset="
                    {{ item.image }}&amp;width=200   200w,
                    {{ item.image }}&amp;width=300   300w,
                    {{ item.image }}&amp;width=400   400w,
                    {{ item.image }}&amp;width=500   500w"
                    fetchpriority="auto"
                    sizes="200px"
                    decoding="async"
                    loading="lazy"
                    alt="{{ item.product_title }}"
                  />
                  {% else %}
                  <img
                    id="product-image-{{ item.id }}"
                    class="boost-sd__product-image-img boost-sd__product-image-img--main"
                    alt="{{ item.product_title }}"
                    src="https://cdn.shopify.com/extensions/201f4d7b-8d39-43a4-82de-e6f412341695/0.0.0/assets/boost-pfs-no-image.jpg?v=1677838268"
                  />
                  {% endif %}
                </div>
              </div>
            </a>
          </div>
          <div class="boost-sd__cart-item-content">
            <div class="boost-sd__cart-item-info">
              <a class="boost-sd__product-link" href="/products/{{ item.handle }}"><div class="boost-sd__cart-item-title">{{ item.product_title }}</div></a>
              {% for option in item.options_with_values %}
              <div class="boost-sd__cart-item-option"><span class="boost-sd__cart-item-option-title">{{ option.name }}: </span><span class="boost-sd__cart-item-option-value">{{ option.value }}</span></div>
              {% endfor %}
              <div class="boost-sd__cart-item-quantity">
                <div class="boost-sd__input-number boost-sd__input-number--sm">
                  <div class="boost-sd__input-number-btn-wrapper">
                    <button type="button" aria-label="-" class="boost-sd__button boost-sd__button--full-width boost-sd__input-number-btn"><span>-</span></button>
                  </div>
                  <input type="number" aria-label="Item quantity" class="boost-sd__input-number-input" value="{{ item.quantity }}" />
                  <div class="boost-sd__input-number-btn-wrapper">
                    <button type="button" aria-label="+" class="boost-sd__button boost-sd__button--full-width boost-sd__input-number-btn"><span>+</span></button>
                  </div>
                </div>
                <div class="boost-sd__cart-item-quantity-price"><span class="boost-sd__cart-item-quantity-character">x</span><span>{{ item.price }}</span></div>
              </div>
            </div>
            <button type="button" title="Remove This Item" class="boost-sd__button boost-sd__cart-item-remove-btn">
              <span class="boost-sd__icon" style="overflow: initial;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24">
                  <path
                    fill="#31373D"
                    d="M9.84 8.8H10.8V16.48H9.84V8.8ZM11.76 8.8H12.72V16.48H11.76V8.8ZM13.68 8.8H14.64V16.48H13.68V8.8ZM6 5.92H18.48V6.88H6V5.92ZM14.64 5.92H13.68V5.44C13.68 5.152 13.488 4.96 13.2 4.96H11.28C10.992 4.96 10.8 5.152 10.8 5.44V5.92H9.84V5.44C9.84 4.624 10.464 4 11.28 4H13.2C14.016 4 14.64 4.624 14.64 5.44V5.92Z"
                  ></path>
                  <path
                    fill="#31373D"
                    d="M15.12 19.3601H9.35996C8.59196 19.3601 7.91996 18.7361 7.82396 17.9681L6.95996 6.44805L7.91996 6.35205L8.78396 17.8721C8.78396 18.1601 9.07196 18.4001 9.35996 18.4001H15.12C15.408 18.4001 15.648 18.1601 15.696 17.8721L16.56 6.35205L17.52 6.44805L16.656 17.9681C16.56 18.7361 15.888 19.3601 15.12 19.3601Z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        {% endfor %}
      </div>
      <div class="boost-sd__cart-footer {% if cartStyle == 'popup' %}boost-sd__cart-footer--right{% endif %}">
        <div class="boost-sd__cart-subtotal {% if cartStyle == 'popup' %}boost-sd__cart-subtotal--right{% endif %}">
          <span class="boost-sd__cart-label">{{ "cart.atcMiniCartSubtotalLabel" | translate: translations, "Subtotal" }}</span><span class="boost-sd__cart-price"><span>{{ items_subtotal_price }}</span></span>
        </div>
        <div class="boost-sd__cart-btn-group {% if cartStyle == 'popup' %}boost-sd__cart-btn-group--right{% endif %}">
          <button
            type="button"
            aria-label="{{ "cart.atcMiniCartCheckoutLabel" | translate: translations, "Checkout" }}"
            class="boost-sd__button {% if cartStyle == 'popup' %}boost-sd__cart-btn{% endif %} boost-sd__button--{{ shape }} boost-sd__button--border boost-sd__button--large boost-sd__btn-checkout"
          >
            <span class="boost-sd__button-text">{{ "cart.atcMiniCartCheckoutLabel" | translate: translations, "Checkout" }}</span>
          </button>
          <button
            type="button"
            aria-label="{{ "cart.atcMiniCartViewCartLabel" | translate: translations, "View cart" }}"
            class="boost-sd__button {% if cartStyle == 'popup' %}boost-sd__cart-btn{% endif %} boost-sd__button--{{ shape }} boost-sd__button--border boost-sd__button--large boost-sd__btn-view-cart"
          >
            <span class="boost-sd__button-text">{{ "cart.atcMiniCartViewCartLabel" | translate: translations, "View cart" }}</span>
          </button>
        </div>
      </div>
    </div>

    <button type="button" aria-label="Close" class="boost-sd__button boost-sd__button--transparent boost-sd__button--float boost-sd__modal-close-btn boost-sd__modal-close-btn--on-overlay">
      <svg class="boost-sd__modal-close-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L12 10.5858L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L13.4142 12L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L12 13.4142L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L10.5858 12L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
          fill="#31373D"
        ></path>
      </svg>
    </button>
    <div tabindex="0" class="boost-sd__modal-last-child"></div>
  </div>
</div>
</div>
`;
