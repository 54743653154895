import { debounce, simpleMd5, debug, stripHtml, xssFilterOption } from '../utils';
import { templateRender } from '../template/templateRender';
import { getTemplate } from '../template';
import { FILTER_OPTION_SELECTOR } from '../constants/filter-tree';
import { getSelectedKeysFilter } from '.';
const SELECTED_CLASS = 'boost-sd__filter-option-box-item--selected';

export function renderOptionBox(context, dom, option, limit) {
  if (!dom) return;
  const selectedKeys = getSelectedKeysFilter(context);

  const classNameUnselected = 'boost-sd__filter-option-item boost-sd__filter-option-box-item';
  const classNameSelected = `${classNameUnselected} ${SELECTED_CLASS}`;

  const [getActionMapping, setActionMapping] = context.useContextState('actionMapping', {});
  const actionMapping = getActionMapping() || {};

  let html = '';
  const size = limit && option.values.length > limit ? limit : option.values.length;
  const values = xssFilterOption(option, size);

  for (const value of values) {
    let className = classNameUnselected;
    if (selectedKeys[`${option.filterOptionId}-${value.key?.toString()?.toLowerCase()}`]) {
      className = classNameSelected;
    }

    value.label = value.label || value.key;

    const actionId = simpleMd5(`${option.filterOptionId}-${value.key}`);
    const dataAction = `${option.filterOptionId}.${actionId}`;

    const action = {
      optionList: {
        key: option.filterOptionId,
        value: stripHtml(value.key.replace("'", '&apos;').replace('"', '&quot;')),
        label: option.label,
        filterType: option.filterType,
        selectType: option.selectType,
        displayType: option.displayType,
        valueDisplay: value.label || '',
      },
    };

    // assign mapping event
    actionMapping[option.filterOptionId] = actionMapping[option.filterOptionId] || {};
    actionMapping[option.filterOptionId][actionId] = action;

    html += templateRender(getTemplate(context).filterOptionBoxTemplate, {
      actionId,
      dataAction,
      value,
      className,
      displayAllValuesInUppercaseForm: option.displayAllValuesInUppercaseForm,
    });
  }

  setActionMapping(actionMapping);
  dom.innerHTML = html;
}

export function lazyLoadOptionBox(context, dom, id, option) {
  const domMouseMove = dom?.closest(FILTER_OPTION_SELECTOR);

  const mousemoveRender = debounce(() => {
    debug(context);
    renderFullOptionBox(context, dom, id, option);

    domMouseMove.removeEventListener('mousemove', mousemoveRender);
    domMouseMove.removeEventListener('touchstart', mousemoveRender);
  }, 200);

  domMouseMove && domMouseMove.addEventListener('mousemove', mousemoveRender);
  domMouseMove && domMouseMove.addEventListener('touchstart', mousemoveRender);
}

/**
 * render full option box
 * @param context
 * @param dom
 * @param id
 * @param option
 */
export function renderFullOptionBox(context, dom, id, option) {
  if (
    context.filterTreeViewPort &&
    context.filterTreeViewPort[id] &&
    context.filterTreeViewPort[id].loaded < context.filterTreeViewPort[id].total &&
    context.filterTreeViewPort[id].displayType === 'box'
  ) {
    renderOptionBox(context, dom, option, context.filterTreeViewPort[id].total);
    context.filterTreeViewPort[id] = {
      loaded: context.filterTreeViewPort[id].total,
      total: option.values.length,
      displayType: 'list',
    };
  }
}

export const addFilterBoxSelectedClass = (context, key, value) => {
  const actionId = simpleMd5(`${key}-${value}`);
  const domElement = context.$(`#${actionId}`);
  domElement && domElement.parentElement.classList.add(SELECTED_CLASS);
};

export const removeFilterBoxSelectedClass = (context, key, value) => {
  const actionId = simpleMd5(`${key}-${value}`);
  const domElement = context.$(`#${actionId}`);
  domElement && domElement.parentElement.classList.remove(SELECTED_CLASS);
};
