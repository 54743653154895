export const translateWithComponent = (str, obj) => {
  const regex = /\{\{(.*?)\}\}/g;

  return str.replace(regex, (match, key) => {
    // Check if the key exists in the object
    key = key?.trim();
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    } else {
      // Key not found, return the original string inside {{ }}
      return match;
    }
  });
};

export const translateByKey = (context, key = '', defaultValue = '') => {
  if (!context?.app?.translation?.[key]) {
    return defaultValue;
  }

  return context.app.translation[key];
};
