import { deleteQueryParamHistory, setPaginationSession } from '../utils/index.js';

export function resetFilterRequest(context) {
  const [_, setPage] = context.useContextState('pagination ', {});
  setPage({ page: 1 });
  deleteQueryParamHistory('page');
  setPaginationSession(1);
  callFilterRequest(context);
}

export const callFilterRequest = (context, addition) => {
  return context.getFilterApi(context.app.filterUrl, context.id, addition);
};
