import {
  getLocalStorage,
  isCartPage,
  isCollectionPage,
  isHomePage,
  isProductPage,
  isSearchPage,
  removeLocalStorage,
  setLocalStorage,
} from '../utils';
const prefix = 'boost-sd__';
const DEFAULT_SELECTOR = {
  productItem: `.${prefix}product-item, .product-item-list-view-layout, .${prefix}suggestion-queries-item--product`,
  addToCart: `.${prefix}btn-add-to-cart,.${prefix}button--select-option[aria-label="Add to cart"], form[action="/cart/add"] *[type="submit"], form[action="/cart/add"] *[name="add"]`,
  buyNow: `.${prefix}btn-buy-now, .shopify-payment-button__button, .shopify-payment-button, #dynamic-checkout-cart`,
  quickView: `.${prefix}button--quick-view`,
  recommend: `.${prefix}recommendation`,
  suggest: `.${prefix}instant-search-container`,
};

window.boostAnalytic = DEFAULT_SELECTOR;

const ANALYTICS_URL = 'https://lambda.mybcapps.com/e';
const USER_ACTION = {
  VIEW_PRODUCT: 'view_product',
  QUICK_VIEW: 'quick_view',
  ADD_TO_CART: 'add_to_cart',
  BUY_NOW: 'buy_now',
};

const PRE_ACTION = 'boostSdPreAction';
const DATA_NOT_SEND = 'boostSdDataNotSent';
const RECOMMEND_ACTIVE = 'boostSdCurrentRecommendActive';

import { PRE_REQUEST_IDS, QUERY_STRING_KEY } from '../constants/app';

export const ACTION = {
  FILTER: 'filter',
  SEARCH: 'search',
  SUGGEST: 'suggest',
  RECOMMEND: 'recommend',
};

/**
 * Generates a random unique session ID
 * @return {string} random unique ID
 */
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

async function refreshCartTokenSendEvent(data) {
  try {
    // save data prepare send to localStore
    setLocalStorage(DATA_NOT_SEND, data);
    setTimeout(async () => {
      const response = await fetch('/cart.js');
      const toJson = await response?.json();

      if (toJson.token) {
        data.ct = toJson.token;
        postData(ANALYTICS_URL, data);
        removeLocalStorage(DATA_NOT_SEND);
      }
    }, 1500);
  } catch (error) {
    console.log('refreshCartTokenSendEvent');
  }
}

async function checkSendEventWhenLoadPage() {
  try {
    const data = getLocalStorage(DATA_NOT_SEND);
    if (data) {
      const response = await fetch('/cart.js');
      const toJson = await response?.json();

      if (toJson.token) {
        data.ct = toJson.token;

        postData(ANALYTICS_URL, data);
        removeLocalStorage(DATA_NOT_SEND);
      }
    }
  } catch (error) {
    console.log('error checkSendEventWhenLoadPage', error);
  }
}

function sendEvent(data) {
  if (data.a === ACTION.RECOMMEND) {
    // not send qs when action recommend
    data.qs = null;
  }
  if (data.a === ACTION.RECOMMEND) {
    const recommendActive = getLocalStorage(RECOMMEND_ACTIVE) || {};
    data = {
      ...data,
      ...recommendActive,
    };
  }

  setLocalStorage(PRE_ACTION, data.a);
  // if cartToken not existed -> get cart -> sendEvent
  if ([USER_ACTION.ADD_TO_CART, USER_ACTION.BUY_NOW].includes(data.u)) {
    refreshCartTokenSendEvent(data);
  } else {
    postData(ANALYTICS_URL, data);
  }
}

export function getCustomerId() {
  // https://community.shopify.com/c/shopify-apis-and-sdks/current-best-way-to-get-logged-in-customer-id/td-p/482773
  return (
    window?.__st?.cid ||
    window?.meta?.page?.customerId ||
    window?.ShopifyAnalytics?.meta?.page?.customerId ||
    window?.ShopifyAnalytics?.lib?.user()?.traits()?.uniqToken
  );
}

function getParamsHistory() {
  const url = new URL(window.location);
  const FILTER_HISTORY_PREFIX = 'pf_';
  const { searchParams } = url;
  const queryParams = new URLSearchParams();

  const sort = searchParams.get('sort');
  const q = searchParams.get('q');
  const collectionId = boostWidgetIntegration?.generalSettings?.collection_id || 0;
  if (sort) queryParams.set('sort', sort);
  if (q) queryParams.set('q', q);
  queryParams.set('collection_scope', collectionId);

  for (let key of searchParams.keys()) {
    if (key.startsWith(FILTER_HISTORY_PREFIX) && !queryParams[key]) {
      queryParams.set(key, searchParams.getAll(key));
    }
  }

  return `${queryParams}`;
}

function getCurrentPage() {
  let currentPage = 'collection_page';

  switch (true) {
    case isCollectionPage():
      currentPage = 'collection_page';
      break;

    case isSearchPage():
      currentPage = 'search_page';
      break;

    case isProductPage():
      currentPage = 'product_page';
      break;

    case isCartPage():
      currentPage = 'cart_page';
      break;

    case isHomePage():
      currentPage = 'home_page';
      break;

    default:
      break;
  }

  return currentPage;
}

export function initAnalytic() {
  if (isSearchPage()) {
    setLocalStorage(QUERY_STRING_KEY, getParamsHistory());
  }
  checkSendEventWhenLoadPage();
  console.log('Analytic register events - Version SSR');
  document.addEventListener('click', onClickTrackingEvent, true);
}

function buildGeneralData(data = {}, option = {}) {
  const query_string = getParamsHistory() || getLocalStorage(QUERY_STRING_KEY);
  const requestIds = getLocalStorage(PRE_REQUEST_IDS) || {};
  const productId = data.productId || '';
  const userAction = data.userAction || USER_ACTION.VIEW_PRODUCT;
  const action = data.action || ACTION.FILTER;
  const requestId = requestIds[action];

  return {
    tid: Shopify.shop, // tenant_id
    qs: query_string,
    eid: generateUUID(), // event_id
    rid: requestId, // request_id
    // ct: null, // cart token only add <=> add_to_cart and buy_now
    pid: productId, // product_id
    t: new Date().toISOString(), // clicked at
    u: userAction, // user action
    a: action || 'other', // action
    r: document.referrer, // referrer
    sid: window.boostWidgetIntegration.getSessionId(), // session_id
    cid: getCustomerId(), // customer_id
    pg: getCurrentPage(), // current page type
    ...option,
  };
}

function getProductIdFromQuickView(activeElement) {
  if (!activeElement) return null;
  const modalQuickView = activeElement.closest('#boost-sd__modal-quickview');

  if (modalQuickView) {
    if (modalQuickView.dataset.productId) {
      return modalQuickView.dataset.productId;
    } else {
      if (modalQuickView.dataset.product) {
        return JSON.parse(modalQuickView.dataset?.product || '{}')?.id;
      }
    }
  }

  return null;
}

function getModalQuickView() {
  return document.getElementById('boost-sd__modal-quickview');
}

function onClickTrackingEvent(event) {
  if (!event || !event.target) return;
  let recommendData = {};

  const activeElement = event.target;
  let action = isSearchPage() ? ACTION.SEARCH : isCollectionPage ? ACTION.FILTER : '';

  if (isProductPage()) {
    action = getLocalStorage(PRE_ACTION) || ACTION.FILTER;
  }

  if (activeElement.closest(boostAnalytic.recommend || DEFAULT_SELECTOR.recommend)) {
    action = ACTION.RECOMMEND;
    const recommendationElement = activeElement.closest(
      boostAnalytic.recommend || DEFAULT_SELECTOR.recommend
    );

    if (recommendationElement && recommendationElement.id) {
      recommendData = getLocalStorage(`boostSdRecommend-${recommendationElement.id}`) || {};
      setLocalStorage(RECOMMEND_ACTIVE, recommendData);
    }
  }

  if (activeElement.closest(boostAnalytic.suggest || DEFAULT_SELECTOR.suggest)) {
    action = ACTION.SUGGEST;
  }

  let data = buildGeneralData({ action }, recommendData);

  // listener event from quick view
  window.addEventListener('boost-sd-open-quick-view', function bindDataActionToQuickView(e) {
    const quickViewModal = getModalQuickView();

    quickViewModal?.setAttribute('data-action-analytic', data?.a);
    if (e?.detail?.pid) quickViewModal?.setAttribute('data-product-id', e?.detail?.pid);

    window.removeEventListener('boost-sd-open-quick-view', bindDataActionToQuickView);
  });

  let isSent = false;

  if (activeElement.closest(boostAnalytic.addToCart || DEFAULT_SELECTOR.addToCart)) {
    isSent = true;
    if (!data.pid) {
      data.pid = getProductIdFromQuickView(activeElement);
    }

    data.u = USER_ACTION.ADD_TO_CART;
    const actionFromQuickView = getModalQuickView()?.dataset.actionAnalytic;
    if (actionFromQuickView) {
      data.a = actionFromQuickView;
    }
  } else if (activeElement.closest(boostAnalytic.buyNow || DEFAULT_SELECTOR.buyNow)) {
    isSent = true;
    if (!data.pid) {
      data.pid = getProductIdFromQuickView(activeElement);
    }

    const actionFromQuickView = getModalQuickView()?.dataset.actionAnalytic;
    if (actionFromQuickView) {
      data.a = actionFromQuickView;
    }

    data.u = USER_ACTION.BUY_NOW;
  } else if (activeElement.closest(boostAnalytic.quickView || DEFAULT_SELECTOR.quickView)) {
    isSent = true;

    data.u = USER_ACTION.QUICK_VIEW;
  } else if (activeElement.closest(boostAnalytic.recommend || DEFAULT_SELECTOR.recommend)) {
    isSent = true;
  }

  // Place Latest Click into Product Item - assign productId
  if (activeElement.closest(boostAnalytic.productItem || DEFAULT_SELECTOR.productItem)) {
    isSent = true;
    const productItem = activeElement.closest(
      boostAnalytic.productItem || DEFAULT_SELECTOR.productItem
    );
    const productId =
      productItem?.dataset?.productId ||
      productItem?.dataset?.id ||
      productItem?.getAttribute('id');

    data.pid = productId;
  }

  // if product page get productId from general Settings
  if (!data.pid && boostSDData?.product?.id) {
    data.pid = boostSDData?.product?.id;
  }

  isSent && sendEvent(data);
}
