import {
  setEventComponentToElement,
  getRateCurrency,
  getUnitCurrency,
  getObjectCurrency,
} from './utils';

const IntegrationMultipleCurrency = (type) => {
  /**
   * Recall callback func of 3rd app
   */
  const callbackFunc = () => {
    const objectCurrency = getObjectCurrency(type);
    if (!objectCurrency) return;
    if (
      typeof objectCurrency !== 'undefined' &&
      typeof objectCurrency.moneyFormats !== 'undefined' &&
      typeof objectCurrency.moneyFormats[window?.mlvShopCurrency] !== 'undefined' &&
      typeof window?.mlvedaload !== 'undefined'
    ) {
      try {
        window?.mlvedaload();
      } catch (error) {
        console.log(error);
      }
    }
    if (typeof window.DoublyGlobalCurrency?.convertAll === 'function')
      window.DoublyGlobalCurrency.convertAll(window.DoublyGlobalCurrency.currentCurrency);
  };

  callbackFunc();
};

export default IntegrationMultipleCurrency;
