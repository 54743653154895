const IntegrationMultipleLanguage = () => {
  const DEFAULT_LANGUAGE = 'en';

  /**
   * Get locale selected from weglot
   */
  const getLocale = () => {
    if (window.Weglot?.initialized && typeof window.Weglot?.getCurrentLang === 'function') {
      return window.Weglot.getCurrentLang() || DEFAULT_LANGUAGE;
    }

    return DEFAULT_LANGUAGE;
  };

  /**
   * Translate search quote to english
   */
  const getSearchTerm = (searchTerm) =>
    new Promise((resolve) => {
      if (getLocale() === DEFAULT_LANGUAGE) resolve(searchTerm);

      window.Weglot?.search(searchTerm, function (englishTerm) {
        resolve(englishTerm);
      });
    });

  /**
   * Passing search quote translated to api
   */
  const ChangeLocalePluginWithSearchKey = {
    name: 'Multiple Language - Weglot - Change Locale Plugin With Search Key',
    apply(builder) {
      builder.on('beforeMethodCall', 'get', async (payload) => {
        payload.args[1].translateSearchParams = await getSearchTerm(payload.args[0]);
      });
    },
  };

  //ChangeLocalePluginWithSearchKey();
};

export default IntegrationMultipleLanguage;
