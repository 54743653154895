import { refineByTemplate } from './refine-by-template';
import { refineByHorizontalTemplate } from './refine-by-horizontal-template';
import { filterOptionListTemplate } from './option-list-template';
import { filterOptionBoxTemplate } from './option-box-template';
import { filterOptionSwatchTemplate } from './option-swatch-template';
import { filterOptionRatingTemplate } from './option-rating-template';
import { filterOptionMultiLevelTagTemplate } from './option-multi-level-tag-template';
import { filterOptionMultiLevelCollection } from './option-multi-level-collection-template';
import { cartTemplate } from './cart-template';
import { productPriceTemplate } from './product-price';
import { searchTabCollectionsTemplate } from './search-tab-collections-template';
import { searchTabPagesTemplate } from './search-tab-pages-template';

const TEMPLATE_MAPPING = {
  refineByTemplate: 'refineBy',
  filterOptionListTemplate: 'filterOptionListItem',
  filterOptionBoxTemplate: 'filterOptionBoxItem',
  filterOptionSwatchTemplate: 'filterOptionSwatchItem',
  filterOptionMultiLevelCollection: 'filterOptionMultiLevelCollectionItem',
  filterOptionMultiLevelTagTemplate: 'filterOptionMultiLevelTagItem',
  filterOptionRatingItemTemplate: 'filterOptionRatingItem',
  cartTemplate: 'cart',
  productPriceTemplate: 'productPrice',
  searchTabCollectionsTemplate: 'searchTabCollections',
  searchTabPagesTemplate: 'searchTabPages',
};
function getCustomizedTemplate(context, templateKey, defaultTemplate) {
  // Comment not Delete - Will uncomment when update lasted default template
  const templateKeyFromMetafield = TEMPLATE_MAPPING[templateKey];
  const templateParsed = context?.app?.templateParsed || {};
  const template = context.app?.template || undefined;
  if (templateKeyFromMetafield && templateParsed && templateParsed[templateKeyFromMetafield]) {
    return templateParsed[templateKeyFromMetafield];
  }
  if (templateKeyFromMetafield && template && template[templateKeyFromMetafield]) {
    return template[templateKeyFromMetafield];
  }

  return defaultTemplate;
}

export function getTemplate(context) {
  return {
    refineByTemplate: getCustomizedTemplate(context, 'refineByTemplate', refineByTemplate),
    refineByHorizontalTemplate: getCustomizedTemplate(
      context,
      'refineByHorizontalTemplate',
      refineByHorizontalTemplate
    ),
    filterOptionListTemplate: getCustomizedTemplate(
      context,
      'filterOptionListTemplate',
      filterOptionListTemplate
    ),
    filterOptionBoxTemplate: getCustomizedTemplate(
      context,
      'filterOptionBoxTemplate',
      filterOptionBoxTemplate
    ),
    filterOptionSwatchTemplate: getCustomizedTemplate(
      context,
      'filterOptionSwatchTemplate',
      filterOptionSwatchTemplate
    ),
    cartTemplate: getCustomizedTemplate(context, 'cartTemplate', cartTemplate),
    productPriceTemplate: getCustomizedTemplate(
      context,
      'productPriceTemplate',
      productPriceTemplate
    ),
    filterOptionRatingTemplate: getCustomizedTemplate(
      context,
      'filterOptionRatingTemplate',
      filterOptionRatingTemplate
    ),
    filterOptionMultiLevelTagTemplate: getCustomizedTemplate(
      context,
      'filterOptionMultiLevelTagTemplate',
      filterOptionMultiLevelTagTemplate
    ),
    filterOptionMultiLevelCollection: getCustomizedTemplate(
      context,
      'filterOptionMultiLevelCollection',
      filterOptionMultiLevelCollection
    ),
    searchTabCollectionsTemplate: getCustomizedTemplate(
      context,
      'searchTabCollectionsTemplate',
      searchTabCollectionsTemplate
    ),
    searchTabPagesTemplate: getCustomizedTemplate(
      context,
      'searchTabPagesTemplate',
      searchTabPagesTemplate
    ),
  };
}
