import { debug, waitFor, isEqual } from '../utils';
export function initState(context) {
  context.watchRender = context.watchRender || {};

  context.triggerStateChange = async function (prop, oldValue, newValue) {
    const watchRender = Object.values(context.watchRender);
    if (typeof context.app.customization[context.blockType]?.beforeRender === 'function') {
      watchRender.unshift({
        watchState: [
          'latestFilterSearchRequest',
          'latestInstantSearchRequest',
          'latestRecommendationRequest',
          'latestQuickViewRequest',
        ],
        callback: context.app.customization[context.blockType].beforeRender,
      });
    }
    if (typeof context.app.customization[context.blockType]?.afterRender === 'function') {
      watchRender.push({
        watchState: [
          'latestFilterSearchRequest',
          'latestInstantSearchRequest',
          'latestRecommendationRequest',
          'latestQuickViewRequest',
        ],
        callback: context.app.customization[context.blockType].afterRender,
      });
    }

    if (typeof context.app.customization[context.blockType]?.appIntegration === 'function') {
      watchRender.push({
        watchState: [
          'latestFilterSearchRequest',
          'latestInstantSearchRequest',
          'latestRecommendationRequest',
          'latestQuickViewRequest',
        ],
        callback: context.app.customization[context.blockType].appIntegration,
      });
    }

    if (typeof context.app.customization.beforeRender === 'function') {
      watchRender.unshift({
        watchState: [
          'latestFilterSearchRequest',
          'latestInstantSearchRequest',
          'latestRecommendationRequest',
          'latestQuickViewRequest',
        ],
        callback: context.app.customization.beforeRender,
      });
    }
    if (typeof context.app.customization.afterRender === 'function') {
      watchRender.push({
        watchState: [
          'latestFilterSearchRequest',
          'latestInstantSearchRequest',
          'latestRecommendationRequest',
          'latestQuickViewRequest',
        ],
        callback: context.app.customization.afterRender,
      });
    }

    watchRender.forEach(async (watch) => {
      if (watch.watchState.includes(prop) && typeof watch.callback === 'function') {
        // TODO DAVID Improve obj reference
        // if (!isEqual(oldValue, newValue)) {
        watch.callback(context);
        // }
      }
    });
  };

  context.state = new Proxy(
    {},
    {
      set: function (obj, prop, value) {
        const oldValue = { ...obj }[prop];
        obj[prop] = value;
        context.triggerStateChange(prop, oldValue, value);
        debug(context);
        return true;
      },
    }
  );
  context.useContextState = function (stateName, initialValue, force = false) {
    if (!stateName)
      console.error(stateName, context, initialValue, 'useContextState: stateName is required');
    if (force) {
      context.state[stateName] = initialValue;
    } else {
      if (context.state[stateName]) {
        // console.log(
        //   `has state name: state[${stateName}] with value: ${JSON.stringify(
        //     context.state[stateName]
        //   )}`
        // );
      } else {
        context.state[stateName] = initialValue;
      }
    }
    function getState() {
      return context.state[stateName];
    }
    function setState(newValue) {
      context.state[stateName] = newValue;
      context.triggerStateChange();
      return context.state[stateName];
    }
    return [getState, setState];
  };
}
