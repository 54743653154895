export const isTagPage = (tags) => {
  if (tags && tags.length > 0) return true;
  return false;
};

export const isCollectionPage = (context) => {
  if (!context) {
    return window.boostWidgetIntegration?.generalSettings?.page === 'collection';
  }

  return context?.app?.generalSettings?.page === 'collection';
};

export const isSearchPage = (context) => {
  if (!context) {
    return window.boostWidgetIntegration?.generalSettings?.page === 'search';
  }

  return context?.app?.generalSettings?.page === 'search';
};

export const isCartPage = (context) => {
  if (!context) {
    return window.boostWidgetIntegration?.generalSettings?.page === 'cart';
  }

  return context?.app?.generalSettings?.page === 'cart';
};

export const isProductPage = (context) => {
  if (!context) {
    return window.boostWidgetIntegration?.generalSettings?.page === 'product';
  }

  return context?.app?.generalSettings?.page === 'product';
};

export const isHomePage = (context) => {
  if (!context) {
    return window.boostWidgetIntegration?.generalSettings?.page === 'index';
  }

  return context?.app?.generalSettings?.page === 'index';
};

export const isPageType = () => {
  return window.location.pathname.indexOf('/collections/types') > -1 ? true : false;
};

export const isVendorPage = () => {
  return window.location.pathname.indexOf('/collections/vendors') > -1 ? true : false;
};

/**
 * get current page
 */
export const getCurrentPage = () => {
  let currentPage = '';

  switch (true) {
    case isCollectionPage():
      currentPage = 'collection_page';
      break;

    case isSearchPage():
      currentPage = 'search_page';
      break;

    case isProductPage():
      currentPage = 'product_page';
      break;

    case isCartPage():
      currentPage = 'cart_page';
      break;

    case isHomePage():
      currentPage = 'home_page';
      break;

    default:
      break;
  }

  return currentPage;
};
