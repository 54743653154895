import {
  deleteQueryParamHistory,
  isCollectionPage,
  scrollToTop,
  setQueryParamHistory,
  slugify,
} from '../utils/index.js';
import { FILTER_TAG_KEY } from '../constants/filter-tree.js';
import { resetFilterRequest } from '../api/filter.js';
import {
  addFilterSelectedClass,
  removeFilterSelectedClass,
  renderSelectedOption,
} from './option-list.js';
import { clearAllFilter } from './index.js';
import { renderCollectionHeader } from '../components/collection-header/index.js';

export function optionCollection(context, action) {
  scrollToTop({ isFilter: true });

  // redirect collection only collection page
  if (isCollectionPage()) {
    redirectCollection(context, action);
  } else {
    // logic search page - still show in refine by
    searchPageCollection(context, action);
  }
}

const redirectCollection = (context, action) => {
  const { key, value, handle, collectionTag } = action;

  addFilterSelectedClass(context, key, value);
  removeFilterSelectedClass(context, key, context.app.generalSettings.collection_id);

  let newUrl = `${window.location.origin}/collections/${handle}`;
  context.app.generalSettings.collection_id = value;
  context.app.generalSettings.collection_handle = handle;

  if (collectionTag) {
    addFilterSelectedClass(context, FILTER_TAG_KEY, collectionTag);

    newUrl = `${newUrl}/${slugify(collectionTag)}`;
    context.app.generalSettings.collection_tags = [collectionTag];
  } else {
    context.app.generalSettings.collection_tags = null;
  }

  history.pushState({}, '', newUrl);
  renderCollectionHeader(context);
  clearAllFilter(context);
};

const searchPageCollection = (context, action) => {
  const { key, value, handle, collectionTag, valueDisplay } = action;
  const [getFilterTree, setFilterTree] = context.useContextState('filter', []);
  const filterTree = getFilterTree();

  const oldParams = filterTree.filter(
    (item) => item.data.key !== key && item.data.key !== FILTER_TAG_KEY
  );

  const newFilterTree = [...oldParams];
  const collection = {
    data: {
      label: 'Collection',
      value,
      key,
      valueDisplay,
      handle,
    },
    metaData: { key, value },
    type: 'list',
  };

  if (collectionTag) {
    const tag = {
      data: {
        label: 'Collection Tag',
        value: collectionTag,
        key: FILTER_TAG_KEY,
        valueDisplay: collectionTag,
      },
      metaData: { key: FILTER_TAG_KEY, value: collectionTag, dependencies: [{ key, value }] },
      type: 'list',
    };
    collection.metaData.dependencies = [{ key: FILTER_TAG_KEY, value: collectionTag }];
    newFilterTree.push(collection);
    newFilterTree.push(tag);
  } else {
    newFilterTree.push(collection);
  }

  setFilterTree(newFilterTree);
  setQueryParamHistory(key, value);

  if (!collectionTag) {
    deleteQueryParamHistory(FILTER_TAG_KEY);
  } else {
    setQueryParamHistory(FILTER_TAG_KEY, collectionTag);
  }

  resetFilterRequest(context);
  context.render(() => {
    renderSelectedOption(context);
  }, ['filter', 'filterTree']);
};
