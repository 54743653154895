export const SELECTED_PRODUCT_PAGE = 'boostSDSelectedProductPage';
export const SELECTED_PRODUCT_ID = 'boostSDSelectedProductId';
export const INITIAL_PAGE = 'boostSDInitialPage';

export const PREV_PAGE = 'boostSDCurrentPreviousPage';
export const NEXT_PAGE = 'boostSDCurrentNextPage';
export const COLLECTION_SELECTED = 'boostSDCurrentCollectionSelected';

export const DEFAULT = 'default';
export const LOAD_MORE = 'load_more';
export const INFINITE = 'infinite_scroll';

export const LIMIT = 'boostSDLimit';
export const LIMIT_SETTING = 'boostSDLimitSetting'; // track productPerPage in product list - themeSettings
